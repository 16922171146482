const state = {
  equipment: {
    list: [],
    edit: false,
    client: null,
    tableSearch: '',
    search: '',
    summarySearch: '',
    updateEquipmentModal: false,
    selectedEquipment: {
      id: null,
      erpEquipmentId: '',
      equipmentCd: '',
      equipmentName: '',
      equipmentDescription: '',
      equipmentSerialNo: '',
      active: true,
    },
    equipmentTable: [],
    equipmentData: [],
    equipmentDataMap: {},
    equipmentLoading: false,
    homeLoading: false,
    serviceProfiles: [],
  },
};

export default state;
