import { action } from 'easy-peasy';

const actions = {
  setScheduleLists: action((state, list) => {
    state.schedule.scheduleMap = list.reduce((map, item) => {
      const id = `${item.equipmentName}`;
      const existingItem = map[id];
      if (!existingItem) {
        map[id] = {
          equipmentName: id,
          rmpDescription: item.equipmentDescription,
          serviceName: item.serviceName,
          percentComplete: item.percentComplete,
          dueDate: item.estimatedDueDate,
          forecastDate: item.forecastDate,
          interval: item.usageTypeDesc,
          projects: [
            ...new Set(
              list
                .filter(
                  (listItem) =>
                    listItem.equipmentName === id && listItem.projectCd
                )
                .map((listItem) => listItem.projectCd)
            ),
          ],
          scheduled: list.some(
            (listItem) => listItem.equipmentName === id && listItem.projectCd
          ),
          woqRequired: item.woqRequired,
          customerCd: item.customerCd,
        };
        return map;
      }

      if (
        parseInt(item.percentComplete) > parseInt(existingItem.percentComplete)
      ) {
        existingItem.serviceName = item.serviceName;
        existingItem.percentComplete = item.percentComplete;
        existingItem.dueDate = item.estimatedDueDate;
        existingItem.forecastDate = item.forecastDate;
        existingItem.interval = item.usageTypeDesc;
      }

      return map;
    }, {});
    state.schedule.scheduleList = Object.values(state.schedule.scheduleMap);

    state.schedule.detailMap = list.reduce((map, item) => {
      const id = `${item.equipmentName}-${item.maintenancePlanName}-${item.componentName}`;
      map[id] = item;
      map[id].selected = false;
      return map;
    }, {});
    state.schedule.detailList = Object.values(state.schedule.detailMap);
  }),
  addScheduleConfiguration: action((state, configuration) => {
    state.schedule.configuration = configuration;
  }),
  setScheduleSearch: action((state, search) => {
    state.schedule.search = search;
  }),
  setScheduleDetailSearch: action((state, search) => {
    state.schedule.detailSearch = search;
  }),
  setScheduleEquipmentName: action(
    (state, { equipmentName, rmpDescription }) => {
      state.schedule.equipmentName = equipmentName;
      state.schedule.rmpDescription = rmpDescription;
    }
  ),
  setScheduleEquipmentData: action((state, equipmentList) => {
    state.schedule.equipmentMap = equipmentList.reduce((dataMap, equipment) => {
      dataMap[equipment.id] = equipment;

      return dataMap;
    }, {});
    state.schedule.equipmentList = equipmentList;
  }),
  setScheduleMaintenancePlans: action((state, list) => {
    state.schedule.maintenancePlans = list;
  }),
  setScheduleDetailListItemSelected: action(
    (state, { rowData, equipmentName }) => {
      let id = `${equipmentName}-${rowData.maintenancePlanName}-${rowData.componentName}`;
      const selectedList = state.schedule.detailList.filter(
        (item) => item.selected
      );

      if (
        selectedList.length > 0 &&
        !selectedList.every((item) => item.equipmentName === equipmentName)
      ) {
        state.schedule.detailMap = state.schedule.list.reduce((map, item) => {
          const id = `${item.equipmentName}-${item.maintenancePlanName}-${item.componentName}`;
          map[id] = item;
          map[id].selected = false;
          return map;
        }, {});
      }

      state.schedule.detailMap[id].selected =
        !state.schedule.detailMap[id].selected;

      state.schedule.detailList = Object.values(state.schedule.detailMap);
    }
  ),
  setScheduleProjectModalVisible: action((state, visible) => {
    state.schedule.projectVisible = visible;
  }),
  setScheduleProjectDescription: action((state, description = null) => {
    state.schedule.projectDescription = description;
  }),
  setScheduleDropdowns: action((state, dropdowns) => {
    state.schedule.dropdowns = dropdowns;
  }),
  setWorkOrderQuote: action((state, quote) => {
    state.schedule.workOrderQuote = quote;
  }),
};

export default actions;
