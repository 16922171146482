import { useStoreActions, useStoreState } from 'easy-peasy';
import { useEffect } from 'react';

import Page from '../containers/Page';
import CreateMaintenanceActionsModal from '../containers/Modal/CreateMaintenanceActionsModal';
import MaintenanceActionsTable from '../containers/Table/MaintenanceActionsTable';
import { Container } from '../presentational/Container';

export default function MaintenanceActions({ ...props }) {
  const { id } = props.route.params;
  const { maintenance, equipment, selectedServiceProfile } = useStoreState(
    (state) => state
  );
  const { selectedEquipment } = equipment;
  const { selectedMaintenancePlan } = maintenance;
  const { getUsageTypesThunk, getMaintenanceActionsThunks } = useStoreActions(
    (actions) => actions
  );

  useEffect(() => {
    getUsageTypesThunk();
    getMaintenanceActionsThunks({
      eqId:
        selectedServiceProfile?.equipmentId ||
        selectedMaintenancePlan.equipmentId,
      id: selectedServiceProfile?.id || selectedMaintenancePlan.id,
    });
    props.navigation.setOptions({
      title: `Maintenance Actions for ${
        selectedServiceProfile?.serviceName ||
        selectedMaintenancePlan.maintenancePlan
      }`,
    });
  }, []);

  return (
    <Page.Protected route={props.route}>
      <Container>
        <CreateMaintenanceActionsModal
          equipmentCd={selectedEquipment.equipmentCd}
        />
        <MaintenanceActionsTable />
      </Container>
    </Page.Protected>
  );
}
