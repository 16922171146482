import { thunk } from 'easy-peasy';
import { Toast } from 'react-native-toast-notifications';

import moment from 'moment';
import {
  getUsageTypesAPICall,
  upsertUsageHistoryAPICall,
  upsertUsageTypesAPICall,
} from '../../api/usage';
import {
  getAcmEquipmentsAPICall,
  getEquipmentUsageAPICall,
} from '../../api/equipment';
import { getUtilizationAPICall } from '../../api/utilization';

const thunks = {
  getUsageLogThunk: thunk(async (actions, equipmentId) => {
    actions.setLoadings({ getUsageLogs: true });
    try {
      const list = await getEquipmentUsageAPICall(equipmentId);
      actions.setUsageAction(list);
    } catch (e) {
      let message = e.message || '';
      e.response && e.response.data && (message = e.response.data.message);
      Toast.show(message, {
        type: 'danger',
        placement: 'bottom',
        duration: 5000,
        offset: 30,
        animationType: 'slide-in',
      });
    }
    actions.setLoadings({
      getUsageLogs: false,
    });
  }),
  getUsageTypesThunk: thunk(async (actions) => {
    actions.setLoadings({ usageTypes: true });
    try {
      const list = await getUsageTypesAPICall();
      actions.setUsageTypes(list);
    } catch (e) {
      let message = e.message || '';
      e.response && e.response.data && (message = e.response.data.message);
      Toast.show(message, {
        type: 'danger',
        placement: 'bottom',
        duration: 5000,
        offset: 30,
        animationType: 'slide-in',
      });
    }
    actions.setLoadings({
      usageTypes: false,
    });
  }),
  upsertUsageHistoryThunk: thunk(async (actions, payload, helpers) => {
    actions.setLoadings({ upsertUsageHistory: true });
    try {
      const usageHistory = helpers.getState().usage.selectedUsage;
      const monthCount = helpers.getState().usage.averageMonthCount;
      const body = {
        ...usageHistory,
        usageDate: moment(usageHistory.dateValue),
      };
      const response = await upsertUsageHistoryAPICall(body);
      const utilization = await getUtilizationAPICall(monthCount);
      const acmEquipment = await getAcmEquipmentsAPICall();
      actions.setAcmEquipment({ equipment: acmEquipment.value, utilization });
      actions.setUsageAction([]);
      actions.setEnterUsageModal(false);
      Toast.show('Usage Saved', {
        type: 'success',
        placement: 'bottom',
        duration: 5000,
        offset: 30,
        animationType: 'slide-in',
      });
    } catch (e) {
      let message = e.message || '';
      e.response && e.response.data && (message = e.response.data.message);
      Toast.show(message, {
        type: 'danger',
        placement: 'bottom',
        duration: 5000,
        offset: 30,
        animationType: 'slide-in',
      });
    }
    actions.setLoadings({
      upsertUsageHistory: false,
    });
  }),
  upsertUsageTypeThunk: thunk(async (actions, payload, helpers) => {
    actions.setLoadings({ upsertUsageType: true });
    try {
      const response = await upsertUsageTypesAPICall(
        helpers.getState().usage.selectedUsageType
      );
      actions.setUsageTypes(response[0]);
      Toast.show('Usage Type Saved', {
        type: 'success',
        placement: 'bottom',
        duration: 5000,
        offset: 30,
        animationType: 'slide-in',
      });
    } catch (e) {
      let message = e.message || '';
      e.response && e.response.data && (message = e.response.data.message);
      Toast.show(message, {
        type: 'danger',
        placement: 'bottom',
        duration: 5000,
        offset: 30,
        animationType: 'slide-in',
      });
    }
    actions.setLoadings({
      upsertUsageType: false,
    });
  }),
};

export default thunks;
