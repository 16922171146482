import React from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Center } from '@cfbs/cfbsstrap-native';
import { FontAwesome5 } from 'react-native-vector-icons';

import Page from './Page';
import Input from '../../presentational/Input';
import Gap from '../../presentational/Gap';
import Icon from '../../presentational/Icon';
import PrimaryButton from '../../presentational/Button/Button';
import { vhToDp } from '../../../utilities/responsive';
import DrawerBrand from '../../presentational/Drawer/DrawerBrand';
import { LoginCard, LoginHeader } from './styles';

function ErpLoginPage() {
  const { erpLogin: loginInput } = useStoreState((state) => state);
  const { setErpLoginAction, loginAcumaticaThunk } = useStoreActions(
    (actions) => actions
  );

  return (
    <Page>
      <Center screen>
        <LoginCard>
          <DrawerBrand height={vhToDp(12)} width='100%' />
          <Gap />
          <LoginHeader>Acumatica Login</LoginHeader>
          <Gap />
          <Input
            onChangeText={(name) =>
              setErpLoginAction({
                ...loginInput,
                name,
              })
            }
            value={loginInput.name}
            placeholder='Type name'
            leftIcon={<Icon Component={FontAwesome5} name='user-alt' />}
            onSubmitEditing={() => loginAcumaticaThunk()}
          />
          <Gap />
          <Input
            onChangeText={(text) =>
              setErpLoginAction({
                ...loginInput,
                password: text,
              })
            }
            value={loginInput.password}
            placeholder='Type password'
            secureTextEntry
            leftIcon={<Icon Component={FontAwesome5} name='lock' />}
            onSubmitEditing={() => loginAcumaticaThunk()}
          />
          <Gap />
          <PrimaryButton
            fullWidth
            style={{ backgroundColor: '#007ACC' }}
            onKeyPress={() => loginAcumaticaThunk(loginInput)}
            onPress={() => loginAcumaticaThunk(loginInput)}
          >
            Login
          </PrimaryButton>
        </LoginCard>
      </Center>
    </Page>
  );
}

export default ErpLoginPage;
