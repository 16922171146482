import { useStoreActions, useStoreState } from 'easy-peasy';
import LoginPage from './LoginPage';
import ErpLoginPage from './ErpLoginPage';
import Page from './Page';
import { useEffect } from 'react';
import Storage from '../../../utilities/Storage';
import { KIOSK_TOKEN_NAME } from '../../../utilities/constants';

function ProtectedPage({ children, route, ...props }) {
  const { isAuthenticated, erpIsAuthenticated, isNested } = useStoreState(
    (state) => state
  );

  const { getAuthUserThunk, nestedLoginThunk, setIsNested } = useStoreActions(
    (actions) => actions
  );

  useEffect(() => {
    if (route?.params && route?.params?.token) {
      setIsNested(true);
      Storage.setItem(KIOSK_TOKEN_NAME, route?.params?.token);
      nestedLoginThunk();
    } else {
      setIsNested(false);
      getAuthUserThunk();
    }
  }, [isNested, route]);

  return (
    <Page {...props}>
      {isNested ? (
        children
      ) : isAuthenticated ? (
        erpIsAuthenticated ? (
          children
        ) : (
          <ErpLoginPage />
        )
      ) : (
        <LoginPage />
      )}
    </Page>
  );
}

export default ProtectedPage;
