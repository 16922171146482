const state = {
  schedule: {
    configuration: {},
    scheduleMap: {},
    scheduleList: [],
    detailMap: {},
    detailList: [],
    equipmentList: [],
    equipmentMap: {},
    equipment: {},
    maintenancePlans: [],
    maintenancePlan: {},
    serviceProfile: {},
    search: '',
    detailSearch: '',
    equipmentName: '',
    rmpDescription: '',
    projectDescription: '',
    projectVisible: false,
    dropdowns: {},
    workOrderQuote: {},
  },
};

export default state;
