import React from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Center } from '@cfbs/cfbsstrap-native';
import {
  MaterialCommunityIcons,
  FontAwesome5,
} from 'react-native-vector-icons';

import Page from './Page';
import Input from '../../presentational/Input';
import Gap from '../../presentational/Gap';
import Icon from '../../presentational/Icon';
import PrimaryButton from '../../presentational/Button/Button';
import { vhToDp } from '../../../utilities/responsive';
import DrawerBrand from '../../presentational/Drawer/DrawerBrand';
import { LoginCard, LoginHeader } from './styles';

function LoginPage() {
  const { login: loginInput } = useStoreState((state) => state);
  const { setLoginAction, loginThunk } = useStoreActions((actions) => actions);

  return (
    <Page>
      <Center screen>
        <LoginCard>
          <DrawerBrand height={vhToDp(12)} width='100%' />
          <Gap />
          <LoginHeader>CFBS RMP App Registration</LoginHeader>
          <Gap />
          <Input
            onChangeText={(email) =>
              setLoginAction({
                ...loginInput,
                email,
              })
            }
            value={loginInput.email}
            placeholder='Type email'
            inputMode='email'
            leftIcon={<Icon Component={MaterialCommunityIcons} name='email' />}
            onSubmitEditing={() => loginThunk()}
          />
          <Gap />
          <Input
            onChangeText={(text) =>
              setLoginAction({
                ...loginInput,
                password: text,
              })
            }
            value={loginInput.password}
            placeholder='Type password'
            secureTextEntry
            leftIcon={<Icon Component={FontAwesome5} name='lock' />}
            onSubmitEditing={() => loginThunk()}
          />
          <Gap />
          <PrimaryButton
            fullWidth
            onKeyPress={() => loginThunk(loginInput)}
            onPress={() => loginThunk(loginInput)}
          >
            Login
          </PrimaryButton>
        </LoginCard>
      </Center>
    </Page>
  );
}

export default LoginPage;
