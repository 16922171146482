import React, { useEffect, useState } from 'react';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { NavigationContainer } from '@react-navigation/native';
import DrawerC from '../containers/Drawer';
import { useStoreState } from 'easy-peasy';
import Schedule from '../pages/Schedule';
import ScheduleDetails from '../pages/ScheduleDetails';
import Equipment from '../pages/Equipment';
import MaintenanceActions from '../pages/MaintenanceActions';
import MaintenancePlans from '../pages/MaintenancePlans';
import UsageHistory from '../pages/UsageHistory';
import UsageEquipment from '../pages/UsageEquipment';
import UsageLog from '../pages/UsageLog';
import UsageTypes from '../pages/UsageTypes';
import UsersAndRoles from '../pages/UsersAndRoles';
import Configuration from '../pages/Configuration';
import { createStackNavigator } from '@react-navigation/stack';

const Drawer = createDrawerNavigator();
const Stack = createStackNavigator();

const ScheduleStack = () => {
  const { isNested } = useStoreState((state) => state);
  return (
    <Stack.Navigator>
      <Stack.Screen
        component={Schedule}
        name='Summary'
        options={{
          title: 'Schedule Summary',
          headerShown: isNested,
        }}
      />
      <Stack.Screen
        component={ScheduleDetails}
        name='Details'
        options={{
          title: 'Details',
        }}
      />
      <Stack.Screen
        component={MaintenanceActions}
        name='Maintenance Actions'
        options={{
          title: 'Maintenance Actions',
        }}
      />
    </Stack.Navigator>
  );
};

const EquipmentStack = () => {
  return (
    <Stack.Navigator>
      <Stack.Screen
        component={Equipment}
        name='List'
        options={{
          title: 'Equipment',
          headerShown: false,
        }}
      />
      <Stack.Screen
        component={MaintenancePlans}
        name='Maintenance Plans'
        options={{
          title: 'Maintenance Plans',
        }}
      />
      <Stack.Screen
        component={MaintenanceActions}
        name='Maintenance Actions'
        options={{
          title: 'Maintenance Actions',
        }}
      />
    </Stack.Navigator>
  );
};

const UsageStack = () => {
  const { isNested } = useStoreState((state) => state);
  return (
    <Stack.Navigator>
      <Stack.Screen
        name='Usage History'
        component={UsageHistory}
        initialParams={{ openModal: 'false' }}
        options={{
          title: 'Usage History',
          headerShown: isNested,
        }}
      />
      <Stack.Screen
        component={UsageEquipment}
        name='Equipment'
        options={{
          title: 'Usage Equipment',
        }}
      />
      <Stack.Screen
        component={UsageLog}
        name='Log'
        options={{
          title: 'Usage Log',
        }}
      />
    </Stack.Navigator>
  );
};

export default function AppDrawerNavigator() {
  const [showHeader, setShowHeader] = useState(false);
  const { isAuthenticated, erpIsAuthenticated, isNested } = useStoreState(
    (state) => state
  );

  useEffect(() => {
    setShowHeader(!isNested && isAuthenticated && erpIsAuthenticated);
  }, [isAuthenticated, erpIsAuthenticated, isNested]);

  const config = {
    screens: {
      Usage: {
        screens: {
          'Usage History': 'usageHistory/:openModal?/:token?',
        },
      },
      Schedule: {
        screens: {
          Summary: 'Summary/:token?',
        },
      },
    },
  };

  return (
    <NavigationContainer
      linking={{
        prefixes: ['https://rmp-test.cfbs-us.com', 'http://localhost:19006'],
        config: config,
      }}
    >
      <Drawer.Navigator
        screenOptions={{
          headerShown: showHeader,
          headerRight: DrawerC.Header,
        }}
        drawerContent={DrawerC.Content}
      >
        <Drawer.Screen
          name='Schedule'
          component={ScheduleStack}
          options={{
            title: 'Schedule',
          }}
        />
        <Drawer.Screen
          name='Equipment'
          component={EquipmentStack}
          options={{
            title: 'Equipment',
          }}
        />
        <Drawer.Screen
          name='Usage'
          component={UsageStack}
          options={{
            title: 'Usage History',
          }}
        />
        <Drawer.Screen
          name='Usage Types'
          component={UsageTypes}
          options={{
            title: 'Usage Types',
          }}
        />
        <Drawer.Screen
          name='Users and Roles'
          component={UsersAndRoles}
          options={{
            title: 'Users and Roles',
          }}
        />
        <Drawer.Screen
          name='Configuration'
          component={Configuration}
          options={{
            title: 'Configuration',
          }}
        />
      </Drawer.Navigator>
    </NavigationContainer>
  );
}
