import { thunk } from 'easy-peasy';
import { Toast } from 'react-native-toast-notifications';

import {
  getMaintenanceActionsAPICall,
  getMaintenancePlansAPICall,
  upsertMaintenanceActionAPICall,
  getServicePackagesAPICall,
} from '../../api/maintenance';
import { getUsageTypesAPICall } from '../../api/usage';

const thunks = {
  getMaintenanceThunks: thunk(async (actions, id, helpers) => {
    actions.setLoadings({ getMaintenance: true });

    try {
      const list = await getMaintenancePlansAPICall();
      actions.setMaintenancePlansAction(list);
      actions.setMaintenanceMap();
    } catch (e) {
      let message = e.message || '';
      e.response && e.response.data && (message = e.response.data.message);
      Toast.show(message, {
        type: 'danger',
        placement: 'bottom',
        duration: 5000,
        offset: 30,
        animationType: 'slide-in',
      });
    }

    actions.setLoadings({
      getMaintenance: false,
    });
  }),
  getMaintenanceActionsThunks: thunk(async (actions, { eqId, id }, helpers) => {
    actions.setLoadings({ getActions: true });
    try {
      const list = await getMaintenanceActionsAPICall(eqId, id);
      actions.setMaintenanceActionsAction(list);
    } catch (e) {
      let message = e.message || '';
      e.response && e.response.data && (message = e.response.data.message);
      Toast.show(message, {
        type: 'danger',
        placement: 'bottom',
        duration: 5000,
        offset: 30,
        animationType: 'slide-in',
      });
    }

    actions.setLoadings({
      getActions: false,
    });
  }),
  upsertMaintenanceActionThunk: thunk(async (actions, payload, helpers) => {
    actions.setLoadings({ upsertAction: true });
    try {
      const response = await upsertMaintenanceActionAPICall(
        helpers.getState().maintenance.maintenanceAction
          .selectedMaintenanceAction
      );
      actions.upsetMaintenanceActions(response[0] || []);
      actions.setMaintenanceActionsModal(false);
      Toast.show('Maintenance Action Saved', {
        type: 'success',
        placement: 'bottom',
        duration: 5000,
        offset: 30,
        animationType: 'slide-in',
      });
    } catch (e) {
      let message = e.message || '';
      e.response && e.response.data && (message = e.response.data.message);
      Toast.show(message, {
        type: 'danger',
        placement: 'bottom',
        duration: 5000,
        offset: 30,
        animationType: 'slide-in',
      });
    }

    actions.setLoadings({
      upsertAction: false,
    });
  }),
  getServicePackagesThunk: thunk(async (actions, payload, helpers) => {
    actions.setLoadings({ packages: true });
    try {
      const list = await getServicePackagesAPICall();
      actions.setServicePackagesAction(list);
    } catch (e) {
      let message = e.message || '';
      e.response && e.response.data && (message = e.response.data.message);
      Toast.show(message, {
        type: 'danger',
        placement: 'bottom',
        duration: 5000,
        offset: 30,
        animationType: 'slide-in',
      });
    }
    actions.setLoadings({ packages: false });
  }),
  getMaintenancePlanTableThunk: thunk(async (actions, eqId) => {
    actions.setLoadings({ tree: true });
    try {
      const plans = await getMaintenancePlansAPICall();
      const usageTypes = await getUsageTypesAPICall();
      actions.setMaintenancePlansAction(plans);
      actions.setMaintenanceMap();
      actions.setUsageTypes(usageTypes);
      actions.setServiceProfileList(eqId);
    } catch (e) {
      let message = e.message || '';
      e.response && e.response.data && (message = e.response.data.message);
      Toast.show(message, {
        type: 'danger',
        placement: 'bottom',
        duration: 5000,
        offset: 30,
        animationType: 'slide-in',
      });
    } finally {
      actions.setLoadings({
        tree: false,
      });
    }
  }),
};

export default thunks;
