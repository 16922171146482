import { useStoreActions } from 'easy-peasy';
import { useEffect } from 'react';

import Page from '../containers/Page';
import UsersAndRolesTable from '../containers/Table/UsersAndRolesTable';
import { Container } from '../presentational/Container';

export default function UsersAndRoles() {
  const { getRolesThunk } = useStoreActions((actions) => actions);

  useEffect(() => {
    function getEquipments() {
      getRolesThunk();
    }

    getEquipments();
  }, []);

  return (
    <Page.Protected>
      <Container>
        <UsersAndRolesTable />
      </Container>
    </Page.Protected>
  );
}
