import { useStoreActions, useStoreState } from 'easy-peasy';
import { useEffect, useState } from 'react';
import { useIsFocused } from '@react-navigation/native';
import { Tabs, Tab } from '@cfbs/cfbsstrap-native';

import Page from '../containers/Page';
import ScheduleDetailsTable from '../containers/Table/ScheduleDetailsTable';
import NameProjectModal from '../containers/Modal/NameProjectModal';
import { Container } from '../presentational/Container';
import ForecastDetailsTable from '../containers/Table/ForecastDetailsTable';

export default function ScheduleDetails({ navigation, route }) {
  const [activeKey, setActiveKey] = useState('current');
  const {
    schedule: { rmpDescription },
  } = useStoreState((state) => state);
  const {
    loadSchedulesThunk,
    loadMaintenancePlans,
    loadDropdownsThunk,
    setSelectedServiceProfile,
    setSelectedMaintenancePlan,
    setSelectedEquipment,
  } = useStoreActions((actions) => actions);

  const isFocused = useIsFocused();

  const loadData = () => {
    loadSchedulesThunk();
    loadMaintenancePlans();
    loadDropdownsThunk();
    setSelectedServiceProfile({});
    setSelectedMaintenancePlan({});
    setSelectedEquipment({});
  };

  useEffect(() => {
    if (isFocused) {
      loadData();
    }
  }, [isFocused]);

  useEffect(() => {
    navigation.setOptions({
      title: `Schedule`,
    });
  }, []);

  return (
    <Page.Protected route={route}>
      <Container>
        <ScheduleDetailsTable
          navigation={navigation}
          route={route}
          onRefresh={loadData}
        />
        <NameProjectModal />
      </Container>
    </Page.Protected>
  );
}
