import { thunk } from 'easy-peasy';
import uuid from 'react-native-uuid';

const thunks = {
  setAlertThunk: thunk(async (actions, alert) => {
    const delay = (ms) => new Promise((res) => setTimeout(res, ms));
    alert.id = uuid.v4();
    actions.addAlertAction(alert);
    await delay(5000);
    actions.removeAlertAction(alert.id);
  }),
};

export default thunks;
