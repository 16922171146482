import { Image as RNImage } from "@rneui/themed";

function Image({ height = "100%", width = "100%", ...props }) {
  return (
    <RNImage
      containerStyle={{ height, width }}
      resizeMode="contain"
      {...props}
    />
  );
}

export default Image;
