import { acmAxios } from '.';

export async function addProjectAPICall(data) {
  const response = await acmAxios({
    method: 'post',
    url: `/addProject`,
    data: data,
  });
  return response.data;
}

export async function getProjectDetailsAPICall(data) {
  const response = await acmAxios({
    method: 'get',
    url: `/getAcumaticaEquipment?&id=${data}`,
  });
  return response.data;
}
