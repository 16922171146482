import { action } from 'easy-peasy';

const actions = {
  setEquipmentAction: action((state, equipment) => {
    state.equipment = {
      ...state.equipment,
      ...equipment,
    };
  }),
  setSummaryList: action((state, list) => {
    state.equipment.summarizedMap = list.reduce((map, item) => {
      const id = `${item.equipmentName}`;
      const existingItem = map[id];
      if (!existingItem) {
        map[id] = {
          equipmentName: id,
          serviceName: item.serviceName,
          percentComplete: item.percentComplete,
          dueDate: item.estimatedDueDate,
          interval: item.usageTypeDesc,
          scheduled: list.some(
            (listItem) => listItem.equipmentName === id && listItem.projectCd
          ),
        };
        return map;
      }

      if (
        parseInt(item.percentComplete) > parseInt(existingItem.percentComplete)
      ) {
        existingItem.serviceName = item.serviceName;
        existingItem.percentComplete = item.percentComplete;
        existingItem.dueDate = item.estimatedDueDate;
        existingItem.interval = item.usageTypeDesc;
      }

      return map;
    }, {});
    state.equipment.summarizedList = Object.values(
      state.equipment.summarizedMap
    );
  }),
  setSelectedEquipmentName: action((state, equipmentName) => {
    state.equipment.equipmentName = equipmentName;
  }),
  setEquipmentList: action((state, list) => {
    state.equipment.listMap = list.reduce((map, item) => {
      const id = `${item.equipmentName}-${item.maintenancePlanName}-${item.componentName}`;
      map[id] = item;
      map[id].selected = false;
      return map;
    }, {});
    state.equipment.list = Object.values(state.equipment.listMap);
  }),
  setEquipmentListItemSelected: action((state, rowData) => {
    let id = `${rowData.equipmentName}-${rowData.maintenancePlanName}-${rowData.componentName}`;
    const selectedList = state.equipment.list.filter((item) => item.selected);

    if (
      selectedList.length > 0 &&
      !selectedList.every(
        (item) => item.equipmentName === rowData.equipmentName
      )
    ) {
      state.equipment.listMap = state.equipment.list.reduce((map, item) => {
        const id = `${item.equipmentName}-${item.maintenancePlanName}-${item.componentName}`;
        map[id] = item;
        map[id].selected = false;
        return map;
      }, {});
    }

    state.equipment.listMap[id].selected =
      !state.equipment.listMap[id].selected;

    state.equipment.list = Object.values(state.equipment.listMap);
  }),
  setListNull: action((state) => {
    state.equipment.list = [];
  }),
  setEquipmentDataSearch: action((state, equipmentDataSearch) => {
    state.equipment.tableSearch = equipmentDataSearch;
  }),
  setSelectedEquipment: action((state, selectedEquipment) => {
    state.equipment.selectedEquipment.id =
      selectedEquipment.id || selectedEquipment.equipmentId;
    state.equipment.selectedEquipment.equipmentId =
      selectedEquipment.id || selectedEquipment.equipmentId;
    state.equipment.selectedEquipment.erpEquipmentId =
      selectedEquipment.erpEquipmentId;
    state.equipment.selectedEquipment.equipmentCd =
      selectedEquipment.equipmentCd;
    state.equipment.selectedEquipment.equipmentName =
      selectedEquipment.equipmentName;
    state.equipment.selectedEquipment.equipmentDescription =
      selectedEquipment.equipmentDescription;
    state.equipment.selectedEquipment.equipmentSerialNo =
      selectedEquipment.equipmentSerialNo;
    state.equipment.selectedEquipment.active = selectedEquipment.active;
    state.equipment.selectedEquipment.treeId = selectedEquipment.treeId || null;
    state.equipment.selectedEquipment.customerCd = selectedEquipment.customerCd;
    state.equipment.selectedEquipment.rmpDescription =
      selectedEquipment.rmpDescription;
  }),
  setUpdateEquipmentModal: action((state, updateEquipmentModal) => {
    state.equipment.updateEquipmentModal = updateEquipmentModal;
  }),
  setEquipmentData: action((state, equipmentData) => {
    state.equipment.equipmentDataMap = equipmentData.reduce(
      (dataMap, equipment) => {
        dataMap[equipment.id] = equipment;

        return dataMap;
      },
      {}
    );
    state.equipment.equipmentData = equipmentData;
  }),
  setEditEquipment: action((state, edit) => {
    state.equipment.edit = edit;
  }),
  setEquipmentLoading: action((state, equipmentLoading) => {
    state.equipment.equipmentLoading = equipmentLoading;
  }),
  setHomeLoading: action((state, homeLoading) => {
    state.equipment.homeLoading = homeLoading;
  }),
};

export default actions;
