import * as SecureStore from 'expo-secure-store';

const state = {
  project: {
    selectedProject: {
      Customer: 'BAKER-DEVE',
      Description: 'Test Project',
      Hold: true,
      ServicePackagesToAdd: 'TESTSVCPROFILE,TESTSVCPROFILE2',
      EquipmentID: '',
      custom: {},
    },
    projectModalVisible: false,
  },
};

export default state;
