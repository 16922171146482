import * as SecureStore from 'expo-secure-store';

const state = {
  user: null,
  modal: {
    isVisible: false,
    message: '',
    success: false,
  },
};

export default state;
