import * as SecureStore from 'expo-secure-store';

const state = {
  usage: {
    search: '',
    equipmentSearch: '',
    logSearch: '',
    usageTypeSearch: '',
    enterUsageModal: false,
    usageTypeModal: false,
    edit: false,
    usageTypes: null,
    selectedUsage: {
      equipmentUsageId: null,
      tenantId: '694B907A-9E90-4D69-86DA-64F0C40AF1BF',
      equipmentId: '',
      hours: '',
      cycles: '',
      landings: '',
      hobbsStart: 0,
      hobbsStop: 0,
      usageDate: '',
      notes: '',
      enteredBy: '1D655266-28CF-41CD-8BCD-E3FE0562AD53',
      flightNumber: '',
    },
    history: [],
    notesModal: false,
    noteIndex: 0,

    selectedUsageType: {
      usageId: 0,
      tenantId: '694B907A-9E90-4D69-86DA-64F0C40AF1BF',
      usageType: '',
      enteredBy: '1D655266-28CF-41CD-8BCD-E3FE0562AD53',
      active: true,
    },
    equipmentId: null,
    equipmentDescription: null,
    logDescription: null,
    logId: null,
    dropdownRef: null,
    usageLoading: false,
    usageTypeMap: {},
    acmEquipment: [],
    averageMonthCount: 3,
  },
};

export default state;
