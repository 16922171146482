import { thunk } from 'easy-peasy';
import {
  addProjectAPICall,
  getProjectDetailsAPICall,
} from '../../api/projects';
import { upsertMaintenancePlanProjectAPICall } from '../../api/maintenance';

const thunks = {
  addProjectThunk: thunk(async (actions, payload, helpers) => {
    actions.setLoadings({ addProjects: true });
    const state = helpers.getState();

    const selectedPlans = state.equipment.list.filter((item) => item.selected);
    const selectedPlanIds = selectedPlans.map((item) => item.maintenancePlanId);
    const plansToUpdate = state.maintenance.maintenancePlans.filter((item) =>
      selectedPlanIds.includes(item.id)
    );
    let servicePackages = '';
    let serviceProfiles = '';

    selectedPlans.forEach((plan) => {
      if (plan.packageOrProfile === 'profile') {
        serviceProfiles += `${plan.serviceId},`;
      }
      if (plan.packageOrProfile === 'package') {
        servicePackages += `${plan.serviceId},`;
      }
    });

    try {
      let body = {
        Description: state.project.description,
        Hold: true,
        ServicePackagesToAdd: servicePackages,
        ServiceProfilesToAdd: serviceProfiles,
      };

      let res = await addProjectAPICall(body);
      actions.setMaintenancePlanItemsProjectCd(res);

      await upsertMaintenancePlanProjectAPICall({
        ids: selectedPlanIds.toString(),
        projectCd: res?.ProjectID?.value,
      });

      actions.setProjectDescription(null);
      actions.setMaintenancePlansAction(null);
      actions.setProjectModalVisible(false);

      actions.setAlertThunk({ type: 'success', message: 'Project Saved' });
    } catch (e) {
      let message = e.message || '';
      e.response && e.response.data && (message = e.response.data);
      actions.setAlertThunk({ type: 'error', message });
    }
    actions.setLoadings({ addProjects: false });
  }),
  getProjectDetailsThunk: thunk(async (actions, payload, helpers) => {
    actions.setLoadings({ getDetails: true });
    try {
      let res = await getProjectDetailsAPICall(
        helpers.getState().equipment.selectedEquipment.erpEquipmentId
      );
      actions.setSelectedProject({
        ...helpers.getState().project.selectedProject,
        Customer: res.value[0].CustomerCd,
      });
      actions.setProjectModalVisible(true);
    } catch (e) {
      let message = e.message || '';
      e.response && e.response.data && (message = e.response.data);
      actions.setAlertThunk({ type: 'error', message });
    }
    actions.setLoadings({ getDetails: false });
  }),
};

export default thunks;
