import { action } from 'easy-peasy';

const actions = {
  setStatusAction: action((state, status) => {
    state.status = {
      ...state.status,
      ...status,
    };
  }),
  setLoadings: action((state, loading) => {
    state.loadings = {
      ...state.loadings,
      ...loading,
    };
  }),
  addAlertAction: action((state, alert) => {
    state.status.alerts = [...state.status.alerts, alert];
  }),
  removeAlertAction: action((state, alertToRemoveId) => {
    state.status.alerts = state.status.alerts.filter(
      (alert) => alert.id !== alertToRemoveId
    );
  }),
};

export default actions;
