import styled from "styled-components/native";

const Container = styled.View`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

function Center({ children, ...props }) {
  return <Container {...props}>{children}</Container>;
}

export default Center;
