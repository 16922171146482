import { useStoreActions, useStoreState } from 'easy-peasy';

import { Active } from './TableUtils';
import Card from '../../presentational/Card';
import RmpTable from '../../presentational/RmpTable';

function UsersAndRolesTable() {
  const {
    roles: { search, list },
  } = useStoreState((state) => state);

  const { setRolesSearch } = useStoreActions((actions) => actions);

  const hasSearchText = (value) =>
    value
      .toString()
      .toLowerCase()
      .trim()
      .includes(search && search?.item.toLowerCase().trim());

  return (
    <Card>
      <RmpTable
        addButton={false}
        onChange={(search) => {
          setRolesSearch(search);
        }}
        onClear={() => {
          setRolesSearch({ item: '' });
        }}
        value={search?.item}
        headers={[
          { columnName: 'Name', propertyName: 'name', type: 'string' },
          { columnName: 'Email', propertyName: 'email', type: 'string' },
          { columnName: 'Roles', propertyName: 'role', type: 'string' },
          { columnName: 'Active', propertyName: 'active', type: 'string' },
        ]}
        widths={[0.26, 0.305, 0.305, 0.13]}
        data={(list || [])
          .filter(
            (role) =>
              hasSearchText(role.Username) || hasSearchText(role.RoleName)
          )
          .map((role) => {
            return {
              name: role.Username,
              email: role.Username,
              role: role.RoleName,
              active: true,
            };
          })}
        conditions={[
          {
            index: 3,
            func: (data, index) => {
              return <Active data={data} index={index} />;
            },
          },
        ]}
      ></RmpTable>
    </Card>
  );
}

export default UsersAndRolesTable;
