import { action } from 'easy-peasy';

const actions = {
  setSelectedProject: action((state, selectedProject) => {
    state.project.selectedProject.Customer = selectedProject.Customer;
    state.project.selectedProject.Description = selectedProject.Description;
    state.project.selectedProject.Hold = selectedProject.Hold;
    state.project.selectedProject.ServicePackagesToAdd =
      selectedProject.ServicePackagesToAdd;
    state.project.selectedProject.Status = selectedProject.Status;
    state.project.selectedProject.EquipmentID =
      state.equipment.selectedEquipment.erpEquipmentId;
  }),
  setProjectModalVisible: action((state, visible) => {
    state.project.projectModalVisible = visible;
  }),
  setProjectDescription: action((state, description = null) => {
    state.project.description = description;
  }),
};

export default actions;
