import { useStoreActions, useStoreState } from 'easy-peasy';
import moment from 'moment/moment';
import { Edit, Notes } from './TableUtils';
import RmpTable from '../../presentational/RmpTable';
import { NotesModal } from '../Modal/NotesModal';
import Card from '../../presentational/Card';

function MaintenanceActionsTable(props) {
  const { maintenance, usage, selectedServiceProfile } = useStoreState(
    (state) => state
  );
  const { notesModal, noteIndex, usageTypeMap } = usage;
  const { maintenanceActions, selectedMaintenancePlan } = maintenance;

  const {
    setMaintenanceActionsModal,
    setEditMaintenance,
    setSelectedMaintenanceAction,
    setNotesModal,
    setNoteIndex,
  } = useStoreActions((actions) => actions);

  const usageName = () => {
    if (selectedServiceProfile?.usageDisplay) {
      return selectedServiceProfile?.usageDisplay;
    }
    if (usageTypeMap) {
      const currentUsage = usageTypeMap[selectedMaintenancePlan?.usageType];
      return `${currentUsage?.usageType} (${selectedMaintenancePlan?.usageInterval})`;
    }
    return '';
  };

  return (
    <Card>
      <NotesModal
        value={
          maintenanceActions &&
          maintenanceActions[noteIndex] &&
          maintenanceActions[noteIndex].notes
        }
        isVisible={notesModal}
        setVisible={setNotesModal}
      />
      <RmpTable
        addButton={true}
        addButtonText='Add Action'
        onPress={() => {
          setSelectedMaintenanceAction({
            id: null,
            equipmentId:
              selectedServiceProfile?.equipmentId ||
              selectedMaintenancePlan.equipmentId,
            maintenancePlanId:
              selectedServiceProfile?.id || selectedMaintenancePlan.id,
            maintenancePlan:
              selectedServiceProfile?.serviceName ||
              selectedMaintenancePlan.maintenancePlan,
            usageType:
              selectedServiceProfile?.usageId ||
              selectedMaintenancePlan?.usageType,
            intervalCompletedAt: 0,
            dateCompleted: moment.utc().format('MM-DD-YYYY'),
            notes: '',
            active: true,
            projectComplete: false,
            dateEntered: null,
            enteredBy: null,
            dateModified: null,
            modifiedBy: null,
          });
          setMaintenanceActionsModal(true);
        }}
        onDismiss={() => setMaintenanceActionsModal(false)}
        headers={[
          { columnName: 'Usage', propertyName: 'usage', type: 'string' },
          {
            columnName: 'Completed Interval At',
            propertyName: 'completedAt',
            type: 'string',
          },
          {
            columnName: 'Completed On',
            propertyName: 'completedDate',
            type: 'date',
          },
          { columnName: 'Notes', propertyName: 'notes', disabled: true },
          { columnName: 'Edit', propertyName: 'edit', disabled: true },
        ]}
        widths={[0.3, 0.3, 0.3, 0.05, 0.05]}
        data={(maintenanceActions || [])
          .sort((a, b) => {
            return new Date(b.dateCompleted) - new Date(a.dateCompleted);
          })
          .map((equipment) => {
            return {
              usage: usageName(),
              completedAt: `${equipment.intervalCompletedAt || 0} ${
                selectedServiceProfile?.usageType ||
                usageTypeMap[selectedMaintenancePlan?.usageType]?.usageType
              }`,
              completedDate:
                moment.utc(equipment.dateCompleted).format('MM/DD/YYYY') || 0,
              notes: equipment.notes,
              edit: false,
            };
          })}
        conditions={[
          {
            index: 3,
            func: (data, index) => {
              return (
                <Notes
                  data={data}
                  index={index}
                  onPress={() => {
                    if (data) {
                      setNoteIndex(index);
                      setNotesModal(true);
                    }
                  }}
                />
              );
            },
          },
          {
            index: 4,
            func: (data, index) => {
              return (
                <Edit
                  data={data}
                  index={index}
                  onPress={() => {
                    setEditMaintenance(true);
                    setSelectedMaintenanceAction({
                      ...maintenanceActions[index],
                      maintenancePlan:
                        selectedServiceProfile?.serviceName ||
                        selectedMaintenancePlan.maintenancePlan,
                      projectComplete: false,
                    });
                    setMaintenanceActionsModal(true);
                  }}
                />
              );
            },
          },
        ]}
      />
    </Card>
  );
}

export default MaintenanceActionsTable;
