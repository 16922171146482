import * as SecureStore from 'expo-secure-store';

const state = {
  roles: {
    list: [],
    search: '',
  },
};

export default state;
