const state = {
  status: {
    success: false,
    error: false,
    alerts: [],
  },
  loadings: {},
};

export default state;
