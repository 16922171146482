import { acmAxios } from '.';

export async function getUsageTypesAPICall() {
  const response = await acmAxios({
    method: 'get',
    url: `/getUsageType`,
  });
  return response.data;
}

export async function upsertUsageHistoryAPICall(data) {
  const response = await acmAxios({
    method: 'post',
    url: `/upsertEquipmentUsage`,
    data: data,
  });
  return response.data;
}

export async function upsertUsageTypesAPICall(data) {
  const response = await acmAxios({
    method: 'post',
    url: `/upsertUsageType`,
    data: data,
  });
  return response.data;
}
