import { useStoreActions, useStoreState } from 'easy-peasy';
import moment from 'moment';
import { Notes } from './TableUtils';
import { NotesModal } from '../Modal/NotesModal';
import Card from '../../presentational/Card';
import RmpTable from '../../presentational/RmpTable';

function UsageLogTable(props) {
  const { usage } = useStoreState((state) => state);
  const {
    history,
    logSearch,
    usageLoading,
    noteIndex,
    notesModal,
    logDescription,
  } = usage;
  const { setNotesModal, setNoteIndex, setUsageLogSearch } = useStoreActions(
    (actions) => actions
  );

  const hasSearchText = (value) =>
    value
      .toString()
      .toLowerCase()
      .trim()
      .includes(logSearch && logSearch.item.toLowerCase().trim());

  return (
    <Card title='Equipment' isLoading={usageLoading}>
      <NotesModal
        value={history && history[noteIndex] && history[noteIndex].notes}
        isVisible={notesModal}
        setVisible={setNotesModal}
      />
      <RmpTable
        text={`Usage Log for ${logDescription}`}
        addButton={false}
        onRefresh={props.onRefresh}
        onChange={(search) => {
          setUsageLogSearch(search);
        }}
        onClear={() => {
          setUsageLogSearch({ item: '' });
        }}
        value={logSearch?.item}
        headers={[
          {
            columnName: 'Equipment',
            propertyName: 'equipment',
            type: 'string',
          },
          {
            columnName: 'Flight Number',
            propertyName: 'flightNumber',
            type: 'string',
          },
          {
            columnName: 'Hobbs Start',
            propertyName: 'hobbsStart',
            type: 'number',
          },
          {
            columnName: 'Hobbs Stop',
            propertyName: 'hobbsStop',
            type: 'number',
          },
          { columnName: 'Hours', propertyName: 'hours', type: 'number' },
          { columnName: 'Cycles', propertyName: 'cycles', type: 'number' },
          { columnName: 'Landings', propertyName: 'landings', type: 'number' },
          { columnName: 'Usage Date', propertyName: 'date', type: 'date' },
          { columnName: 'Notes', propertyName: 'note', disabled: true },
        ]}
        widths={[0.25, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.05]}
        data={(history || [])
          .sort((a, b) => {
            return new Date(b.usageDate) - new Date(a.usageDate);
          })
          .filter(
            (equipment) =>
              hasSearchText(
                `${equipment.description} (${equipment.equipmentCd})`
              ) ||
              hasSearchText(equipment.flightNumber || 'N/A') ||
              hasSearchText(equipment.hobbsStart) ||
              hasSearchText(equipment.hobbsStop) ||
              hasSearchText(equipment.hours) ||
              hasSearchText(equipment.cycles) ||
              hasSearchText(equipment.landings) ||
              hasSearchText(
                moment.utc(equipment.usageDate).format('MM/DD/YYYY')
              )
          )
          .map((item) => {
            return {
              equipment: `${item.description} (${item.equipmentCd})`,
              flightNumber: item.flightNumber || 'N/A',
              hobbsStart: parseFloat(item.hobbsStart || 0).toFixed(2),
              hobbsStop: parseFloat(item.hobbsStop || 0).toFixed(2),
              hours: parseFloat(item.hours || 0).toFixed(2),
              cycles: parseFloat(item.cycles || 0).toFixed(0),
              landings: parseFloat(item.landings || 0).toFixed(0),
              date: moment.utc(item.usageDate).format('MM/DD/YYYY'),
              note: { notes: item.notes, isOverride: item.isOverride },
            };
          })}
        conditions={[
          {
            index: 8,
            func: (data, index) => {
              return (
                <Notes
                  data={data.notes}
                  error={data.isOverride}
                  index={index}
                  onPress={() => {
                    setNoteIndex(index);
                    setNotesModal(true);
                  }}
                />
              );
            },
          },
        ]}
      />
    </Card>
  );
}

export default UsageLogTable;
