import { useStoreActions, useStoreState } from 'easy-peasy';
import { useCallback } from 'react';
import { useFocusEffect } from '@react-navigation/native';

import Page from '../containers/Page';
import UsageLogTable from '../containers/Table/UsageLogTable';
import EnterUsageModal from '../containers/Modal/EnterUsageModal';
import { Container } from '../presentational/Container';

export default function UsageLog({ route }) {
  const {
    usage: { logId },
    isAuthenticated,
    erpIsAuthenticated,
  } = useStoreState((state) => state);
  const { getUsageLogThunk } = useStoreActions((actions) => actions);

  const loadData = () => {
    getUsageLogThunk(logId);
  };

  useFocusEffect(
    useCallback(() => {
      loadData();
    }, [logId])
  );

  return (
    <Page.Protected route={route}>
      <Container>
        <EnterUsageModal disableEquipment={!!logId} />
        <UsageLogTable onRefresh={loadData} />
      </Container>
    </Page.Protected>
  );
}
