import { useStoreActions, useStoreState } from 'easy-peasy';
import EntypoIcon from 'react-native-vector-icons/Entypo';
import { useState } from 'react';
import { Col, Row, Pressable } from '@cfbs/cfbsstrap-native';
import moment from 'moment';
import { FontAwesome5 } from 'react-native-vector-icons';

import RMPModal from '../../presentational/Modal';
import Input from '../../presentational/Input';
import DatePicker from '../../presentational/DatePicker';
import Icon from '../../presentational/Icon';

export default function EnterUsageModal({ ...props }) {
  const { usage } = useStoreState((state) => state);

  const { enterUsageModal, selectedUsage, acmEquipment } = usage;

  const {
    usageDate,
    hours,
    cycles,
    landings,
    notes,
    hobbsStart,
    hobbsStop,
    flightNumber,
    isOverride,
  } = selectedUsage;

  const { setEnterUsageModal, upsertUsageHistoryThunk, setSelectedUsage } =
    useStoreActions((actions) => actions);

  const [openCalendar, setOpenCalendar] = useState(false);
  const [currentEquipment, setCurrentEquipment] = useState();

  async function AddUsage() {
    const currentHobbs = parseFloat(currentEquipment.CurrentHobbs || 0).toFixed(
      2
    );
    if (hobbsStart !== currentHobbs) {
      setSelectedUsage({
        ...selectedUsage,
        isOverride: true,
        notes: `Hobbs start was manually overridden. Expected ${currentHobbs} but ${hobbsStart} was entered. \n\n ${notes}`,
      });
    }
    await upsertUsageHistoryThunk();
    setSelectedUsage({
      equipmentUsageId: null,
      equipmentId: null,
      hours: '',
      cycles: '',
      landings: '',
      usageDate: moment.utc().format('MM/DD/YYYY'),
      notes: '',
    });
    setCurrentEquipment({});
    setEnterUsageModal(false);
  }

  return (
    <RMPModal
      onClose={() => {
        setCurrentEquipment({});
        setSelectedUsage({
          equipmentUsageId: null,
          equipmentId: null,
          hours: '',
          cycles: '',
          landings: '',
          usageDate: moment.utc().format('MM/DD/YYYY'),
          notes: '',
        });
        setEnterUsageModal(false);
      }}
      onSubmit={() => {
        AddUsage();
      }}
      open={enterUsageModal}
      headerText={'Enter Usage'}
      children={
        <>
          <Row>
            <Col xs={6}>
              <Pressable onPress={() => setOpenCalendar(true)}>
                <Input
                  label='Usage Date'
                  value={
                    usageDate
                      ? moment.utc(usageDate).format('MM/DD/YYYY')
                      : moment.utc().format('MM/DD/YYYY')
                  }
                  rightIcon={
                    <Icon Component={FontAwesome5} name='calendar-alt'></Icon>
                  }
                />
              </Pressable>
              <DatePicker
                visible={openCalendar}
                onBackdropPress={() => setOpenCalendar(false)}
                onChange={(date) => {
                  setSelectedUsage({
                    ...selectedUsage,
                    usageDate: moment.utc(date).format('MM/DD/YYYY'),
                    dateValue: date,
                  });

                  setOpenCalendar(false);
                }}
              ></DatePicker>
            </Col>
            <Col xs={6}>
              <Input
                label='Equipment'
                placeholder='Select Equipment'
                onSelect={(selectedItem) => {
                  setCurrentEquipment(selectedItem);
                  setSelectedUsage({
                    ...selectedUsage,
                    equipmentCd: selectedItem.EquipmentCd,
                    acmId: selectedItem.Id,
                    description: selectedItem.ComponentDescription,
                    equipmentIds: selectedItem.equipment.map((eq) => {
                      return {
                        equipmentCd: eq.EquipmentCd,
                        description: eq.ComponentDescription,
                      };
                    }),
                    hobbsStart: parseFloat(
                      selectedItem.CurrentHobbs || 0
                    ).toFixed(2),
                  });
                }}
                editable={!props.disableEquipment}
                dropdown
                search
                selectedItem={(item) =>
                  `${item.ComponentDescription} (${item.EquipmentCd})`
                }
                rowTextForSelection={(item) =>
                  `${item.ComponentDescription} (${item.EquipmentCd})`
                }
                dropdownChoices={acmEquipment}
                searchInputStyle={{
                  padding: '0 10px',
                  fontFamily: 'Barlow_400Regular',
                  color: '#007ACC',
                  borderBottomWidth: 1,
                }}
                searchInputTxtStyle={{
                  color: '#007ACC',
                  fontFamily: 'Barlow_400Regular',
                }}
                renderSearchInputLeftIcon={() => {
                  return (
                    <Icon Component={EntypoIcon} name='magnifying-glass' />
                  );
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={4}>
              <Input
                label='Flight Number'
                value={flightNumber}
                onChangeText={(text) => {
                  setSelectedUsage({
                    ...selectedUsage,
                    flightNumber: text,
                  });
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={3}>
              <Input
                label='Hobbs Start'
                onChangeText={(text) =>
                  setSelectedUsage({
                    ...selectedUsage,
                    hobbsStart: text,
                  })
                }
                value={hobbsStart}
              />
            </Col>
            <Col xs={3}>
              <Input
                label='Hobbs Stop'
                onChangeText={(text) =>
                  setSelectedUsage({
                    ...selectedUsage,
                    hobbsStop: text,
                    hours: (parseFloat(text || 0) - hobbsStart).toFixed(2),
                  })
                }
                value={hobbsStop}
              />
            </Col>
            <Col xs={2}>
              <Input label='Hours' editable={false} value={hours} />
            </Col>
            <Col xs={2}>
              <Input
                label='Cycles'
                onChangeText={(text) =>
                  setSelectedUsage({
                    ...selectedUsage,
                    cycles: text,
                  })
                }
                value={cycles}
              />
            </Col>
            <Col xs={2}>
              <Input
                label='Landings'
                onChangeText={(text) =>
                  setSelectedUsage({
                    ...selectedUsage,
                    landings: text,
                  })
                }
                value={landings}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Input
                label='Notes'
                multiline={true}
                numberOfLines={4}
                value={notes}
                onChangeText={(text) => {
                  setSelectedUsage({
                    ...selectedUsage,
                    notes: text,
                  });
                }}
              />
            </Col>
          </Row>
        </>
      }
    />
  );
}
