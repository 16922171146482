import { Button, Col, Modal, Row } from '@cfbs/cfbsstrap-native';
import PrimaryButton from '../presentational/Button/Button';

function RMPModal({
  open,
  headerText,
  onClose,
  onSubmit,
  children,
  showSubmit = true,
  style,
}) {
  return (
    <>
      <Modal visible={open} onRequestClose={onClose} style={style}>
        <Modal.Header>{headerText}</Modal.Header>

        <Modal.Body>{children}</Modal.Body>

        <Modal.Footer>
          <Row style={{ width: '100%' }}>
            <Col xs={showSubmit ? 6 : 12}>
              <Button fullWidth onPress={onClose} type='light'>
                Cancel
              </Button>
            </Col>
            {showSubmit && (
              <Col xs={6}>
                <PrimaryButton fullWidth onPress={onSubmit}>
                  Submit
                </PrimaryButton>
              </Col>
            )}
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default RMPModal;
