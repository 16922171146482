import RMPModal from '../../presentational/Modal';
import { Text } from '@cfbs/cfbsstrap-native';

const NotesModal = ({ ...props }) => {
  return (
    <RMPModal
      style={{ width: '20rem' }}
      showSubmit={false}
      headerText='Notes'
      open={props.isVisible}
      children={<Text>{props.value}</Text>}
      onClose={() => {
        props.setVisible(false);
      }}
    />
  );
};

export { NotesModal };
