import styled from 'rn-css';
import { Text } from '@cfbs/cfbsstrap-native';
import { remToDp } from '../../../utilities/responsive';

const DrawerTab = styled(Text)`
  font-size: ${remToDp(1)}px;
  padding: 12px;

  &:hover {
    background-color: #ededed;
  }
`;

export default DrawerTab;
