import { refreshTokenApiCall } from '../api/auth';
import Storage from './Storage';

function formatAuthHeader(token, type = 'Bearer') {
  return `${type} ${token}`;
}

function axiosInterceptor(axios, store, key) {
  axios.interceptors.request.use(async (config) => {
    config.headers.Authorization = formatAuthHeader(await getAuthToken(key));
    return config;
  });

  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error?.response?.status === 401) {
        // refresh token
        const newAccessTokenResponse = await refreshTokenApiCall(key);
        const newAccessToken = newAccessTokenResponse.data.accessToken;
        await setAuthToken(key, newAccessToken);
        error.config.headers.Authorization = formatAuthHeader(newAccessToken);
        return Promise.resolve(await axios.request(error.config));
      }

      if (
        error?.response?.status === 500 &&
        (error?.config?.url.includes('/auth/token/refresh') ||
          error?.response?.data === 'Cannot refresh erp access token')
      ) {
        const actions = store.getActions();
        actions.setErpLoggedIn(false);
      }

      if (
        error?.response?.status === 500 &&
        error?.response?.data === 'Cannot refresh access token'
      ) {
        const actions = store.getActions();
        actions.setLoggedIn(false);
        actions.setErpLoggedIn(false);
      }

      return Promise.reject(error);
    }
  );
}

function getAuthToken(key) {
  return Storage.getItem(key);
}

async function setAuthToken(key, token) {
  await Storage.setItem(key, token);
}

async function removeAuthToken(key) {
  await Storage.removeItem(key);
}

export {
  formatAuthHeader,
  setAuthToken,
  axiosInterceptor,
  removeAuthToken,
  getAuthToken,
};
