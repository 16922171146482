import { action } from 'easy-peasy';

const actions = {
  setUserAction: action((state, user) => {
    state.user = {
      ...state.user,
      ...user,
    };
  }),
  setMessageModalAction: action((state, success, message) => {
    state.modal.success = success;
    state.modal.message = message;
    state.modal.isVisible = true;
  }),
  setUserNull: action((state) => {
    state.user = null;
  }),
};

export default actions;
