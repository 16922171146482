import { acmAxios } from '.';

export async function addWorkOrderQuoteAPICall(data) {
  const response = await acmAxios({
    method: 'post',
    url: `/addProgram`,
    data: data,
  });
  return response.data;
}
