import styled from 'styled-components/native';
import { Card, Text } from '@cfbs/cfbsstrap-native';
import { remToDp } from '../../../utilities/responsive';

export const LoginCard = styled(Card)`
  max-width: 800px;
  background-color: white;
  width: 100%;
  margin: auto;
  padding: 24px;
  box-shadow: 0px 0px 10px grey;
`;

export const LoginHeader = styled(Text)`
  font-size: ${remToDp(1.75)}px;
  text-align: center;
`;
