import { useStoreActions, useStoreState } from 'easy-peasy';
import moment from 'moment';
import { Pressable, Text } from '@cfbs/cfbsstrap-native';

import Card from '../../presentational/Card';
import RmpTable from '../../presentational/RmpTable';
import { useEffect, useState } from 'react';

function UsageEquipmentTable(props) {
  const [listData, setListData] = useState([]);
  const { usage } = useStoreState((state) => state);
  const {
    equipmentSearch,
    usageLoading,
    acmEquipment,
    equipmentId,
    equipmentDescription,
  } = usage;
  const { setUsageEquipmentSearch, setUsageLogDescription, setUsageLogId } =
    useStoreActions((actions) => actions);

  useEffect(() => {
    if (equipmentId) {
      const data = acmEquipment.find(
        (item) => item.EquipmentCd === equipmentId
      );
      setListData([...data.equipment]);
    } else {
      setListData([]);
    }

    return () => {
      setListData(null);
    };
  }, [equipmentId]);

  const hasSearchText = (value) =>
    value
      .toString()
      .toLowerCase()
      .trim()
      .includes(equipmentSearch && equipmentSearch.item.toLowerCase().trim());

  const openLog = (rowData) => {
    setUsageLogId(rowData.EquipmentCd);
    setUsageLogDescription(rowData.Equipment);
    props.navigation.navigate('Log', {
      token: props.route?.params?.token,
    });
  };

  const Click = (rowData, action, linkText = '') => (
    <Pressable
      onPress={() => {
        action();
      }}
    >
      <Text
        style={{
          fontSize: 18,
          color: '#0000ee',
          fontFamily: 'Barlow_400Regular',
          textDecoration: 'underline',
          textAlign: 'left',
          justifyContent: 'left',
          alignItems: 'left',
        }}
      >
        {linkText}
      </Text>
    </Pressable>
  );

  return (
    <Card title='Equipment' isLoading={usageLoading}>
      <RmpTable
        text={`Equipment for ${equipmentDescription}`}
        addButton={false}
        onRefresh={props.onRefresh}
        onPress={() => Open()}
        onDismiss={() => Close()}
        onChange={(search) => {
          setUsageEquipmentSearch(search);
        }}
        onClear={() => {
          setUsageEquipmentSearch({ item: '' });
        }}
        value={equipmentSearch?.item}
        headers={[
          {
            columnName: 'Equipment',
            propertyName: 'Equipment',
            type: 'string',
          },
          {
            columnName: 'Current Hobbs',
            propertyName: 'CurrentHobbs',
            type: 'number',
          },
          {
            columnName: 'Total Hours',
            propertyName: 'TotalHours',
            type: 'number',
          },
          { columnName: 'Cycles', propertyName: 'Cycles', type: 'number' },
          { columnName: 'Landings', propertyName: 'Landings', type: 'number' },
          {
            columnName: 'Usage Date',
            propertyName: 'LastUsageDate',
            type: 'date',
          },
          {
            columnName: 'Status',
            propertyName: 'Status',
            type: 'string',
          },
          { columnName: '', propertyName: '', disabled: true },
        ]}
        widths={[0.3, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1]}
        data={(listData || [])
          .filter(
            (equipment) =>
              hasSearchText(
                `${equipment.ComponentDescription} (${equipment.EquipmentCd})`
              ) ||
              hasSearchText(
                parseFloat(equipment.CurrentHobbs || 0).toFixed(2)
              ) ||
              hasSearchText(parseFloat(equipment.TotalHours || 0).toFixed(2)) ||
              hasSearchText(parseFloat(equipment.Cycles || 0).toFixed(2)) ||
              hasSearchText(parseFloat(equipment.Landings || 0).toFixed(2)) ||
              hasSearchText(equipment.Status) ||
              hasSearchText(
                equipment.LastUsageDate
                  ? moment.utc(equipment.LastUsageDate).format('MM/DD/YYYY')
                  : 'N/A'
              )
          )
          .map((item) => {
            return {
              Equipment: `${item.ComponentDescription} (${item.EquipmentCd})`,
              CurrentHobbs: parseFloat(item.CurrentHobbs || 0).toFixed(2),
              TotalHours: parseFloat(item.TotalHours || 0).toFixed(2),
              Cycles: parseFloat(item.Cycles || 0).toFixed(2),
              Landings: parseFloat(item.Landings || 0).toFixed(2),
              Date: item.LastUsageDate
                ? moment.utc(item.LastUsageDate).format('MM/DD/YYYY')
                : 'N/A',
              Status: item.Status,
              EquipmentCd: item.EquipmentCd,
            };
          })}
        conditions={[
          {
            index: 7,
            func: (data, index, rowData) => {
              return Click(rowData, () => openLog(rowData), 'Log');
            },
          },
        ]}
      />
    </Card>
  );
}

export default UsageEquipmentTable;
