import { thunk } from 'easy-peasy';
import { Toast } from 'react-native-toast-notifications';

import { getConfigurationAPI } from '../../api/configuration';
import {
  getEquipmentsAPICall,
  getEquipmentTableAPICall,
} from '../../api/equipment';
import {
  getMaintenancePlansAPICall,
  upsertMaintenancePlanProjectAPICall,
} from '../../api/maintenance';
import { addProjectAPICall } from '../../api/projects';
import { addWorkOrderQuoteAPICall } from '../../api/workOrderQuotes';
import { getShopEstimateDataAPICall } from '../../api/shopEstimate';

const thunks = {
  loadSchedulesThunk: thunk(async (actions) => {
    actions.setLoadings({ schedule: true });

    try {
      const [equipmentList, equipmentTableList, configuration] =
        await Promise.all([
          getEquipmentsAPICall(),
          getEquipmentTableAPICall(),
          getConfigurationAPI(),
        ]);
      actions.setScheduleLists(equipmentList);
      actions.setScheduleEquipmentData(equipmentTableList);
      actions.addScheduleConfiguration(configuration);
    } catch (e) {
      let message = e.message || '';
      e.response && e.response.data && (message = e.response.data.message);
      Toast.show(message, {
        type: 'danger',
        placement: 'bottom',
        duration: 5000,
        offset: 30,
        animationType: 'slide-in',
      });
    }

    actions.setLoadings({
      schedule: false,
    });
  }),
  loadMaintenancePlans: thunk(async (actions) => {
    actions.setLoadings({ getMaintenance: true });

    try {
      const list = await getMaintenancePlansAPICall();
      actions.setScheduleMaintenancePlans(list);
    } catch (e) {
      let message = e.message || '';
      e.response && e.response.data && (message = e.response.data.message);
      Toast.show(message, {
        type: 'danger',
        placement: 'bottom',
        duration: 5000,
        offset: 30,
        animationType: 'slide-in',
      });
    }

    actions.setLoadings({
      getMaintenance: false,
    });
  }),
  addScheduleProjectThunk: thunk(async (actions, payload, helpers) => {
    actions.setLoadings({ addProjects: true });
    const state = helpers.getState();

    const selectedPlans = state.schedule.detailList.filter(
      (item) => item.selected
    );
    const selectedPlanIds = selectedPlans.map((item) => item.maintenancePlanId);
    const plansToUpdate = state.schedule.maintenancePlans.filter((item) =>
      selectedPlanIds.includes(item.id)
    );
    let packages = [];
    let profiles = [];

    selectedPlans.forEach((plan) => {
      if (plan.packageOrProfile === 'profile') {
        profiles.push({
          profileID: plan.serviceId,
          equipmentID: plan.erpEquipmentId,
        });
      }
      if (plan.packageOrProfile === 'package') {
        packages.push({
          packageID: plan.serviceId,
          equipmentID: plan.erpEquipmentId,
        });
      }
    });

    try {
      let body = {
        Description: state.schedule.projectDescription,
        Hold: true,
        packages,
        profiles,
        Customer: plansToUpdate[0]?.customerCd,
      };

      let res = await addProjectAPICall(body);
      actions.setMaintenancePlanItemsProjectCd(res?.ProjectID?.value);

      await upsertMaintenancePlanProjectAPICall({
        ids: selectedPlanIds.toString(),
        projectCd: res?.ProjectID?.value,
      });

      const equipmentList = await getEquipmentsAPICall();

      actions.setScheduleLists(equipmentList);
      actions.setProjectDescription(null);
      actions.setMaintenancePlansAction(null);
      actions.setProjectModalVisible(false);

      Toast.show('Project Saved', {
        type: 'success',
        placement: 'bottom',
        duration: 5000,
        offset: 30,
        animationType: 'slide-in',
      });
    } catch (e) {
      let message = e.message || '';
      e.response && e.response.data && (message = e.response.data.message);
      Toast.show(message, {
        type: 'danger',
        placement: 'bottom',
        duration: 5000,
        offset: 30,
        animationType: 'slide-in',
      });
    }
    actions.setLoadings({ addProjects: false });
  }),
  addScheduleWorkOrderQuoteThunk: thunk(async (actions, payload, helpers) => {
    actions.setLoadings({ addWorkOrderQuote: true });
    const state = helpers.getState();

    const selectedPlans = state.schedule.detailList.filter(
      (item) => item.selected
    );
    const selectedPlanIds = selectedPlans.map((item) => item.maintenancePlanId);
    const plansToUpdate = state.schedule.maintenancePlans.filter((item) =>
      selectedPlanIds.includes(item.id)
    );
    let packages = [];
    let profiles = [];

    selectedPlans.forEach((plan) => {
      if (plan.packageOrProfile === 'profile') {
        profiles.push({
          profileID: plan.serviceId,
        });
      }
      if (plan.packageOrProfile === 'package') {
        packages.push({
          packageID: plan.serviceId,
        });
      }
    });

    try {
      let body = {
        ...state.schedule.workOrderQuote,
        packages,
        profiles,
        customer: plansToUpdate[0]?.customerCd,
      };

      let res = await addWorkOrderQuoteAPICall(body);
      actions.setMaintenancePlanItemsProjectCd(res?.QuoteNbr?.value);

      await upsertMaintenancePlanProjectAPICall({
        ids: selectedPlanIds.toString(),
        projectCd: res?.QuoteNbr?.value,
      });

      const equipmentList = await getEquipmentsAPICall();

      actions.setScheduleLists(equipmentList);
      actions.setProjectDescription(null);
      actions.setMaintenancePlansAction(null);
      actions.setProjectModalVisible(false);

      Toast.show('Work Order Quote Saved', {
        type: 'success',
        placement: 'bottom',
        duration: 5000,
        offset: 30,
        animationType: 'slide-in',
      });
    } catch (e) {
      let message = e.message || '';
      e.response && e.response.data && (message = e.response.data.message);
      Toast.show(message, {
        type: 'danger',
        placement: 'bottom',
        duration: 5000,
        offset: 30,
        animationType: 'slide-in',
      });
    }
    actions.setLoadings({ addWorkOrderQuote: false });
  }),
  loadDropdownsThunk: thunk(async (actions) => {
    actions.setLoadings({ dropdown: true });

    try {
      const response = await getShopEstimateDataAPICall();
      actions.setScheduleDropdowns(response);
    } catch (e) {
      let message = e.message || '';
      e.response && e.response.data && (message = e.response.data.message);
      Toast.show(message, {
        type: 'danger',
        placement: 'bottom',
        duration: 5000,
        offset: 30,
        animationType: 'slide-in',
      });
    }

    actions.setLoadings({
      dropdown: false,
    });
  }),
};

export default thunks;
