import { action } from 'easy-peasy';

const actions = {
  setUsageAction: action((state, usage) => {
    state.usage.history = usage || [];
  }),
  setUsageEquipmentId: action((state, eqId) => {
    state.usage.equipmentId = eqId;
  }),
  setUsageEquipmentDescription: action((state, description) => {
    state.usage.equipmentDescription = description;
  }),
  setUsageLogId: action((state, logId) => {
    state.usage.logId = logId;
  }),
  setUsageLogDescription: action((state, description) => {
    state.usage.logDescription = description;
  }),
  setEnterUsageModal: action((state, enterUsageModal) => {
    state.usage.enterUsageModal = enterUsageModal;
  }),
  setUsageTypeModal: action((state, usageTypeModal) => {
    state.usage.usageTypeModal = usageTypeModal;
  }),
  setSelectedUsage: action((state, selectedUsage) => {
    state.usage.selectedUsage.equipmentUsageId =
      selectedUsage.id || selectedUsage.equipmentUsageId;

    state.usage.selectedUsage.tenantId = selectedUsage.tenantId;
    state.usage.selectedUsage.equipmentCd =
      selectedUsage.equipmentCd || selectedUsage.id;
    state.usage.selectedUsage.hobbsStart = selectedUsage.hobbsStart;
    state.usage.selectedUsage.hobbsStop = selectedUsage.hobbsStop;
    state.usage.selectedUsage.hours = selectedUsage.hours;
    state.usage.selectedUsage.cycles = selectedUsage.cycles;
    state.usage.selectedUsage.landings = selectedUsage.landings;
    state.usage.selectedUsage.usageDate = selectedUsage.usageDate;
    state.usage.selectedUsage.notes = selectedUsage.notes;
    state.usage.selectedUsage.dateValue = selectedUsage.dateValue;
    state.usage.selectedUsage.flightNumber = selectedUsage.flightNumber;
    state.usage.selectedUsage.acmId = selectedUsage.acmId;
    state.usage.selectedUsage.equipmentIds = selectedUsage.equipmentIds;
    state.usage.selectedUsage.description = selectedUsage.description;
    state.usage.selectedUsage.isOverride = selectedUsage.isOverride || false;
  }),
  setSelectedUsageType: action((state, selectedUsageType) => {
    state.usage.selectedUsageType.usageId = selectedUsageType.usageId;
    state.usage.selectedUsageType.tenantId = selectedUsageType.tenantId;
    state.usage.selectedUsageType.usageType = selectedUsageType.usageType;
    state.usage.selectedUsageType.enteredBy = selectedUsageType.enteredBy;
    state.usage.selectedUsageType.active = selectedUsageType.active;
  }),

  setEditIndex: action((state, edit) => {
    state.usage.history[edit][3] = !state.history[edit][3];

    state.usage.editIndex = edit;
    state.usage.edit = !state.edit;
  }),
  setUsageTypes: action((state, usageTypes) => {
    state.usage.usageTypes = usageTypes;
    state.usage.usageTypeMap = usageTypes.reduce((map, usage) => {
      map[usage.id] = usage;
      return map;
    }, {});
  }),

  setUsageId: action((state, usageId) => {
    state.usage.selectedUsageType.usageId = usageId;
  }),
  setUsageType: action((state, usageType) => {
    state.usage.selectedUsageType.usageType = usageType;
  }),
  setActive: action((state, active) => {
    state.usage.selectedUsageType.active = active;
  }),

  setUsageSearch: action((state, search) => {
    state.usage.search = search;
  }),
  setUsageEquipmentSearch: action((state, search) => {
    state.usage.equipmentSearch = search;
  }),
  setUsageLogSearch: action((state, search) => {
    state.usage.logSearch = search;
  }),
  setUsageTypeSearch: action((state, search) => {
    state.usage.usageTypeSearch = search;
  }),
  setUsageLoading: action((state, loading) => {
    state.usage.usageLoading = loading;
  }),
  setNotesModal: action((state, notesModal) => {
    state.usage.notesModal = notesModal;
  }),
  setNoteIndex: action((state, noteIndex) => {
    state.usage.noteIndex = noteIndex;
  }),

  setAverageMonthCount: action((state, monthCount) => {
    state.usage.averageMonthCount = monthCount;
  }),

  setAcmEquipment: action((state, { equipment, utilization }) => {
    const utilizationMap = utilization.reduce((map, item) => {
      map[item.equipmentCd] = item;
      return map;
    }, {});
    const parentEquipment = equipment.filter((item) => !item.RootComponentID);
    const listData = parentEquipment.map((item) => {
      return {
        ...item,
        average: utilizationMap[item.EquipmentCd]?.averageHours,
        totalMonthHours: utilizationMap[item.EquipmentCd]?.totalHourMonth,
        equipment: equipment.filter(
          (listItem) => listItem.RootComponentID === item.TreeComponentId
        ),
      };
    });
    state.usage.acmEquipment = [...listData];
  }),
};

export default actions;
