import { View } from 'react-native';
import styled from 'rn-css';
import { Row, Col, Text } from '@cfbs/cfbsstrap-native';
import DrawerBrand from '../../presentational/Drawer/DrawerBrand';

const Container = styled(Row)`
  width: 100%;
  justify-content: space-between;
`;

const SText = styled(Text)`
  text-align: right;

  @media (max-width: 576px) {
    display: none;
  }
`;

function DrawerHeader(props) {
  return (
    <Container {...props}>
      <Col xs={0} sm={3}>
        <></>
      </Col>
      <Col xs={0} sm={4} style={{ padding: 0 }}>
        <DrawerBrand />
      </Col>
      <Col xs={0} sm={5}>
        <View>
          <SText>Welcome to RMP</SText>
        </View>
      </Col>
    </Container>
  );
}

export default DrawerHeader;
