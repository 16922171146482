import RMPModal from '../../presentational/Modal';
import { Pressable, Text } from '@cfbs/cfbsstrap-native';
import { useEffect, useState } from 'react';
import { FlatList } from 'react-native';
import { useStoreState } from 'easy-peasy';

import Center from '../../presentational/Center';

const ProjectListModal = ({ ...props }) => {
  const { user } = useStoreState((state) => state);
  const [list, setList] = useState();

  useEffect(() => {
    const flatList = props?.projects?.map((project) => {
      let url = '';
      if (project.startsWith('WQ')) {
        url = `${user.baseURL}/Main?ScreenId=CF304550&QuoteNbr=${project}`;
      } else {
        url = `${user.baseURL}/Main?ScreenId=PM301000&BaseType=P&ContractCD=${project}`;
      }
      return {
        name: project,
        url,
      };
    });
    setList(flatList);
  }, [props.projects]);

  return (
    <RMPModal
      style={{ width: '15rem' }}
      showSubmit={false}
      headerText='Projects/Quotes'
      open={props.isVisible}
      children={
        <Center>
          <FlatList
            data={list}
            renderItem={({ item }) => (
              <Pressable
                onPress={() => {
                  window.open(item.url, '_blank');
                }}
              >
                <Text
                  style={{
                    fontSize: 18,
                    color: '#0000ee',
                    fontFamily: 'Barlow_400Regular',
                    textDecoration: 'underline',
                    textAlign: 'left',
                    justifyContent: 'left',
                    alignItems: 'left',
                    cursor: 'pointer',
                  }}
                >
                  {item.name}
                </Text>
              </Pressable>
            )}
          />
        </Center>
      }
      onClose={() => {
        props.setVisible(false);
      }}
    />
  );
};

export { ProjectListModal };
