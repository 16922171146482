import { useStoreActions, useStoreState } from 'easy-peasy';
import { Col, Row } from '@cfbs/cfbsstrap-native';
import { useEffect, useState } from 'react';

import RMPModal from '../../presentational/Modal';
import Input from '../../presentational/Input';
import Switch from '../../presentational/Switch';

export default function CreateMaintenanceActionsModal({ ...props }) {
  const { equipmentCd } = props;
  const { maintenance, usage, selectedServiceProfile } = useStoreState(
    (state) => state
  );
  const { maintenanceActionsModal, maintenanceAction, edit } = maintenance;
  const { selectedMaintenanceAction } = maintenanceAction;
  const { usageTypes, usageTypeMap } = usage;
  const {
    setMaintenanceActionsModal,
    upsertMaintenanceActionThunk,
    setEditMaintenance,
    setSelectedMaintenanceAction,
  } = useStoreActions((actions) => actions);
  const [defaultValue, setDefaultValue] = useState(null);

  const {
    usageType,
    intervalCompletedAt,
    dateCompleted,
    notes,
    maintenancePlan,
    projectComplete,
  } = selectedMaintenanceAction;

  useEffect(() => {
    const value = usageTypeMap && usageType ? usageTypeMap[usageType] : null;
    setDefaultValue(value?.usageType);
  }, [usageType]);

  return (
    <RMPModal
      onSubmit={async () => {
        await upsertMaintenanceActionThunk();
        setEditMaintenance(false);
        setMaintenanceActionsModal(false);
      }}
      onClose={() => {
        setEditMaintenance(false);
        setMaintenanceActionsModal(false);
      }}
      open={maintenanceActionsModal}
      headerText={
        edit
          ? ` Edit Maintenance Actions for ${equipmentCd}`
          : `Add Maintenance Actions for ${equipmentCd}`
      }
      children={
        <>
          <Row>
            <Col xs={12}>
              <Input
                label='Service Profile/Package'
                editable={false}
                value={maintenancePlan}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Input
                label='Usage Type'
                placeholder='Select Usage'
                onSelect={(selectedItem) =>
                  setSelectedMaintenanceAction({
                    ...selectedMaintenanceAction,
                    usageType: selectedItem.id,
                  })
                }
                value={defaultValue}
                editable
                dropdown
                selectedItem={(item) => item.usageType.toString()}
                rowTextForSelection={(item) => item.usageType.toString()}
                dropdownChoices={usageTypes}
              />
            </Col>
            <Col xs={6}>
              <Input
                label={`Completed At Interval: (${defaultValue})`}
                onChangeText={(text) =>
                  setSelectedMaintenanceAction({
                    ...selectedMaintenanceAction,
                    intervalCompletedAt: text,
                  })
                }
                value={intervalCompletedAt}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Input
                label='Notes'
                multiline={true}
                numberOfLines={4}
                value={notes}
                onChangeText={(text) => {
                  setSelectedMaintenanceAction({
                    ...selectedMaintenanceAction,
                    notes: text,
                  });
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Switch
                label='Remove Project'
                onValueChange={() =>
                  setSelectedMaintenanceAction({
                    ...selectedMaintenanceAction,
                    projectComplete: !projectComplete,
                  })
                }
                value={projectComplete}
              />
            </Col>
          </Row>
        </>
      }
    />
  );
}
