import { useStoreActions, useStoreState } from 'easy-peasy';
import { useEffect } from 'react';
import { useIsFocused } from '@react-navigation/native';

import Page from '../containers/Page';
import EquipmentPageTable from '../containers/Table/EquipmentPageTable';
import { Container } from '../presentational/Container';

export default function Equipment({ navigation }) {
  const { isAuthenticated, erpIsAuthenticated } = useStoreState(
    (state) => state
  );
  const { getEquipmentsTableThunk } = useStoreActions((actions) => actions);
  const isFocused = useIsFocused();

  useEffect(() => {
    if (isFocused) {
      if (isAuthenticated && erpIsAuthenticated) {
        getEquipmentsTableThunk();
      }
    }
  }, [isFocused, isAuthenticated, erpIsAuthenticated]);

  return (
    <Page.Protected>
      <Container>
        <EquipmentPageTable navigation={navigation} />
      </Container>
    </Page.Protected>
  );
}
