import { useStoreActions, useStoreState } from 'easy-peasy';
import { useEffect } from 'react';
import JSONTree from 'react-native-json-tree';

import Page from '../containers/Page';
import { Container } from '../presentational/Container';

export default function Configuration() {
  const { configuration } = useStoreState((state) => state);
  const { getConfigurationThunk } = useStoreActions((actions) => actions);

  useEffect(() => {
    function getConfiguration() {
      getConfigurationThunk();
    }
    getConfiguration();
  }, []);

  return (
    <Page.Protected>
      <Container>
        <JSONTree
          data={configuration}
          hideRoot={true}
          shouldExpandNode={(keyName, data, level) => {
            return level < 2;
          }}
        />
      </Container>
    </Page.Protected>
  );
}
