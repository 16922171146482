import { thunk } from 'easy-peasy';
import { Toast } from 'react-native-toast-notifications';
import {
  loginAcumaticaAPICall,
  loginAPICall,
  getAuthUserAPICall,
  logoutAPICall,
  nestedLoginAPICall,
} from '../../api/auth';
import Storage from '../../utilities/Storage';
import { ERP_TOKEN_NAME, KIOSK_TOKEN_NAME } from '../../utilities/constants';
import { getConfigurationAPI } from '../../api/configuration';

const thunks = {
  getAuthUserThunk: thunk(async (actions) => {
    actions.setLoadings({ getUser: true });

    try {
      const response = await getAuthUserAPICall();
      actions.setUserAction(response.data);
      actions.setLoggedIn(true);
      const config = await getConfigurationAPI();
      actions.addConfigurationData(config);
      actions.setErpLoggedIn(true);
    } catch (e) {
      let message = e?.message || '';
      e?.response && e?.response?.data && (message = e?.response?.data);
      e?.response &&
        e?.response?.data?.message &&
        (message = e?.response?.data?.message);
      Toast.show(message, {
        type: 'danger',
        placement: 'bottom',
        duration: 5000,
        offset: 30,
        animationType: 'slide-in',
      });
    }

    actions.setLoadings({ getUser: false });
  }),
  loginThunk: thunk(async (actions, { email, password }) => {
    actions.setLoadings({ login: true });

    try {
      const response = await loginAPICall(email, password);
      const { token, ...user } = response.data;
      Storage.setItem(KIOSK_TOKEN_NAME, token);
      actions.setUserAction(user);
      actions.setLoggedIn(true);
      Toast.show('Application Registered', {
        type: 'success',
        placement: 'bottom',
        duration: 5000,
        offset: 30,
        animationType: 'slide-in',
      });
    } catch (e) {
      let message = e?.message || '';
      e?.response &&
        e?.response?.data &&
        (message = e?.response?.data?.message);
      Toast.show(message, {
        type: 'danger',
        placement: 'bottom',
        duration: 5000,
        offset: 30,
        animationType: 'slide-in',
      });
    }

    actions.setLoadings({
      login: false,
    });
  }),
  loginAcumaticaThunk: thunk(async (actions, payload) => {
    actions.setLoadings({ loginAcumatica: true });

    try {
      const response = await loginAcumaticaAPICall(payload);
      Storage.setItem(ERP_TOKEN_NAME, response.data.erpToken);
      actions.setErpLoggedIn(true);
      Toast.show('Logged in Successfully', {
        type: 'success',
        placement: 'bottom',
        duration: 5000,
        offset: 30,
        animationType: 'slide-in',
      });
    } catch (e) {
      let message = e.message || '';
      e.response && e.response.data && (message = e.response.data.message);
      Toast.show(message, {
        type: 'danger',
        placement: 'bottom',
        duration: 5000,
        offset: 30,
        animationType: 'slide-in',
      });
    }

    actions.setLoadings({
      loginAcumatica: false,
    });
  }),
  logoutThunk: thunk(async (actions, navigation) => {
    actions.setLoadings({ logout: true });
    try {
      await logoutAPICall();
      Storage.removeItem(ERP_TOKEN_NAME);
      actions.setErpLoggedIn(false);
      navigation && navigation.navigate('Home');
      navigation && navigation.closeDrawer();
    } catch (error) {}
    actions.setLoadings({ logout: false });
  }),
  nestedLoginThunk: thunk(async (actions) => {
    actions.setLoadings({ nestedLogin: true });

    try {
      const response = await nestedLoginAPICall();
      const { token, erpToken, user } = response.data;
      actions.setUserAction(user);
      Storage.setItem(KIOSK_TOKEN_NAME, token);
      actions.setLoggedIn(true);
      Storage.setItem(ERP_TOKEN_NAME, erpToken);
      actions.setErpLoggedIn(true);
    } catch (e) {
      let message = e?.message || '';
      e?.response &&
        e?.response?.data &&
        (message = e?.response?.data?.message);
      Toast.show(message, {
        type: 'danger',
        placement: 'bottom',
        duration: 5000,
        offset: 30,
        animationType: 'slide-in',
      });
    }

    actions.setLoadings({
      nestedLogin: false,
    });
  }),
};

export default thunks;
