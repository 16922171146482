import { useStoreActions, useStoreState } from 'easy-peasy';
import RMPModal from '../../presentational/Modal';
import { Col, Div, Row } from '@cfbs/cfbsstrap-native';

import Input from '../../presentational/Input';
import Switch from '../../presentational/Switch';

export default function UsageTypeModal() {
  const { usageTypeModal, usageTypes, selectedUsageType } = useStoreState(
    (state) => state.usage
  );
  const { setUsageTypeModal, setUsageType, setActive, upsertUsageTypeThunk } =
    useStoreActions((actions) => actions);
  const { active } = selectedUsageType;

  const UploadType = async () => {
    await upsertUsageTypeThunk();
    setUsageTypeModal(false);
  };

  return (
    <RMPModal
      onSubmit={() => {
        UploadType();
      }}
      onClose={() => {
        setUsageTypeModal(false);
      }}
      open={usageTypeModal}
      headerText={'Create / Edit Usage Type'}
      children={
        <>
          <Row>
            <Col xs={12}>
              <Input
                label='Usage Type'
                placeholder='Select Type'
                onSelect={(selectedItem) =>
                  setUsageType(selectedItem.usageType)
                }
                value={selectedUsageType.usageType}
                editable={false}
                dropdown
                selectedItem={(item) => `${item.usageType}`}
                rowTextForSelection={(item) => `${item.usageType}`}
                dropdownChoices={usageTypes}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Switch
                label='Active'
                onValueChange={() => setActive(!active)}
                value={active}
              />
            </Col>
          </Row>
        </>
      }
    />
  );
}
