import { useStoreActions, useStoreState } from 'easy-peasy';
import { Platform } from 'react-native';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { View } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';
import { CheckBox } from 'react-native-elements';
import { Pressable, Text, Row, Col } from '@cfbs/cfbsstrap-native';

import RmpTable from '../../presentational/RmpTable';
import Card from '../../presentational/Card';
import { Active } from './TableUtils';
import Icon from '../../presentational/Icon';

function ScheduleDetailsTable({ navigation, route, onRefresh }) {
  const [disabledProject, setDisabledProject] = useState(true);
  const { schedule, user } = useStoreState((state) => state);

  const {
    detailList,
    detailSearch,
    equipmentName,
    configuration,
    rmpDescription,
    maintenancePlans,
  } = schedule;

  const {
    setScheduleDetailListItemSelected,
    setScheduleProjectDescription,
    setScheduleProjectModalVisible,
    setScheduleDetailSearch,
  } = useStoreActions((actions) => actions);

  useEffect(() => {
    const disabled = detailList.every((item) => !item.selected);
    setDisabledProject(disabled);
  }, [detailList]);

  const Open = () => {
    setScheduleProjectDescription('');
    setScheduleProjectModalVisible(true);
  };

  const Close = () => {
    setScheduleProjectModalVisible(false);
    setScheduleProjectDescription('');
  };

  const SelectPlan = ({ index, rowData }) => {
    const disabled = !!rowData.project;
    return (
      <View
        style={{
          width: '100%',
          height: '30px',
          alignItems: 'center',
          alignContent: 'center',
          alignSelf: 'center',
          justifyContent: 'center',
        }}
      >
        <CheckBox
          checkedColor='#007ACC'
          checked={rowData.selected}
          disabled={disabled}
          onPress={() => {
            setScheduleDetailListItemSelected({ rowData, equipmentName });
          }}
        />
      </View>
    );
  };

  const hasSearchText = (value) =>
    value
      .toLowerCase()
      .trim()
      .includes(detailSearch && detailSearch.item.toLowerCase().trim());

  const Click = ({ data, index, rowData }) => {
    let percent = 0;
    let config = {
      warning: 75,
      stop: 90,
    };

    if (rowData) {
      const interval = rowData?.interval;
      const intervalType = interval?.split(' ');

      if (configuration && configuration?.usageTypes) {
        config = configuration?.usageTypes[intervalType[0]];
      }
      percent = rowData?.dates?.percentComplete;
    }

    return (
      <Row>
        {percent >= config?.stop && (
          <Icon
            style={{ fontSize: '24px', paddingRight: '5px' }}
            Component={MaterialIcons}
            name='error'
            color='red'
          />
        )}
        {percent >= config?.warning && percent < config?.stop && (
          <Icon
            style={{ fontSize: '24px', paddingRight: '5px' }}
            Component={MaterialIcons}
            name='warning'
            color='yellow'
          />
        )}
        <div
          style={{
            paddingTop: '1px',
          }}
        >
          <Text
            style={{
              paddingTop: '1px',
              fontSize: 18,
              color: 'black',
              fontFamily: 'Barlow_400Regular',
            }}
          >
            {data || ''}
          </Text>
        </div>
      </Row>
    );
  };

  const PlanDescription = ({ data, index, rowData }) => {
    const plan = maintenancePlans.find((item) => item.maintenancePlan === data);

    return (
      <Text
        style={{
          paddingTop: '1px',
          fontSize: 18,
          color: 'black',
          fontFamily: 'Barlow_400Regular',
        }}
      >
        {plan.serviceName || ''}
      </Text>
    );
  };

  const OpenProject = (data, index, rowData) => (
    <Pressable
      onPress={() => {
        if (data && Platform.OS == 'web') {
          let url = '';
          if (data.startsWith('WQ')) {
            url = `${user.baseURL}/Main?ScreenId=CF304550&QuoteNbr=${data}`;
          } else {
            url = `${user.baseURL}/Main?ScreenId=PM301000&BaseType=P&ContractCD=${data}`;
          }
          window.open(url, '_blank');
        }
      }}
    >
      <Text
        style={{
          fontSize: 18,
          color: '#0000ee',
          fontFamily: 'Barlow_400Regular',
          textDecoration: 'underline',
        }}
      >
        {data}
      </Text>
    </Pressable>
  );

  const DateCol = (rowData) => {
    return (
      <>
        <Row>
          <Col
            style={{ paddingTop: '4px', paddingBottom: '4px' }}
            xs={12}
            xl={6}
          >
            Estimated
          </Col>
          <Col
            style={{
              paddingTop: '4px',
              paddingBottom: '4px',
              textAlign: 'right',
            }}
            xs={12}
            xl={6}
          >
            {rowData.dates.estimated}
          </Col>
        </Row>
        <Row>
          <Col
            style={{ paddingTop: '4px', paddingBottom: '4px' }}
            xs={12}
            xl={6}
          >
            Forecasted
          </Col>
          <Col
            style={{
              paddingTop: '4px',
              paddingBottom: '4px',
              textAlign: 'right',
            }}
            xs={12}
            xl={6}
          >
            {rowData.dates.forecasted}
          </Col>
        </Row>
        {/*<Row>
        <Col style={{ paddingTop: '4px', paddingBottom: '4px' }} xs={6}>
          Start
        </Col>
        <Col
          style={{
            paddingTop: '4px',
            paddingBottom: '4px',
            textAlign: 'right',
          }}
          xs={6}
        >
          {rowData.logs.forecast}
        </Col>
      </Row> */}
      </>
    );
  };

  return (
    <Card>
      <RmpTable
        text={`Schedules for ${rmpDescription}`}
        addButton={true}
        addButtonText='Schedule'
        addButtonDisabled={disabledProject}
        onRefresh={onRefresh}
        onPress={() => Open()}
        onDismiss={() => Close()}
        onChange={(search) => {
          setScheduleDetailSearch(search);
        }}
        onClear={() => {
          setScheduleDetailSearch({ item: '' });
        }}
        value={detailSearch?.item}
        headers={[
          { columnName: '', propertyName: 'selected' },
          {
            columnName: 'Component',
            propertyName: 'componentName',
            type: 'string',
          },
          {
            columnName: 'Maintenance',
            propertyName: 'maintenancePlanName',
            type: 'string',
          },
          { columnName: 'Interval', propertyName: 'interval', type: 'string' },
          {
            columnName: 'Date',
            propertyName: 'dueDate',
            type: 'date',
            disabled: true,
          },
          { columnName: 'Work Complete', propertyName: 'workComplete' },
          { columnName: 'Project', propertyName: 'project', type: 'string' },
        ]}
        widths={[0.05, 0.2, 0.3, 0.1, 0.2, 0.075, 0.075]}
        data={(detailList || [])
          .sort((a, b) => {
            const percentA = parseInt(a.percentComplete);
            const percentB = parseInt(b.percentComplete);
            if (percentA < percentB) {
              return 1;
            }
            if (percentA > percentB) {
              return -1;
            }
            return 0;
          })
          .filter(
            (equipment) =>
              equipment.equipmentName === equipmentName &&
              (hasSearchText(equipment.equipmentName) ||
                hasSearchText(equipment.maintenancePlanName) ||
                hasSearchText(equipment.componentName) ||
                hasSearchText(equipment.usageTypeDesc) ||
                hasSearchText(equipment.projectCd || '') ||
                hasSearchText(equipment?.usageInterval?.toString() || '') ||
                hasSearchText(equipment.currentUsageUnits.toString() || '') ||
                hasSearchText(equipment.percentComplete.toString() || '') ||
                hasSearchText(
                  moment.utc(equipment.estimatedDueDate).format('MM/DD/YYYY') ||
                    ''
                ) ||
                hasSearchText(
                  moment.utc(equipment.forecastDate).format('MM/DD/YYYY') || ''
                ))
          )
          .map((equipment) => {
            const usage = equipment.usageInterval
              ? `${equipment.currentUsageUnits} of`
              : '';
            const interval = equipment.usageInterval
              ? `${equipment.usageTypeDesc}\n(${usage}${equipment.usageInterval})`
              : equipment.usageTypeDesc;
            return {
              selected: equipment.selected,
              componentName: equipment.componentName,
              maintenancePlanName: equipment.maintenancePlanName,
              interval: interval,
              dates: {
                estimated: equipment.estimatedDueDate
                  ? moment.utc(equipment.estimatedDueDate).format('MM/DD/YYYY')
                  : 'N/A',
                forecasted: equipment.forecastDate
                  ? moment.utc(equipment.forecastDate).format('MM/DD/YYYY')
                  : 'N/A',
                percentComplete: equipment.percentComplete,
              },
              workComplete: equipment.workComplete,
              project: equipment.projectCd,
            };
          })}
        conditions={[
          {
            index: 0,
            func: (data, index, rowData) => {
              return <SelectPlan index={index} rowData={rowData} />;
            },
          },
          {
            index: 1,
            func: (data, index, rowData) => {
              return <Click data={data} index={index} rowData={rowData} />;
            },
          },
          {
            index: 2,
            func: (data, index, rowData) => {
              return (
                <PlanDescription data={data} index={index} rowData={rowData} />
              );
            },
          },
          {
            index: 4,
            func: (data, index, rowData) => {
              return DateCol(rowData);
            },
          },
          {
            index: 5,
            func: (data, index) => {
              return <Active data={data} index={index} />;
            },
          },
          {
            index: 6,
            func: (data, index, rowData) => {
              return OpenProject(data, index, rowData);
            },
          },
        ]}
      />
    </Card>
  );
}

export default ScheduleDetailsTable;
