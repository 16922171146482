import { useStoreActions, useStoreState } from 'easy-peasy';

import { Active, Edit } from './TableUtils';
import Card from '../../presentational/Card';
import RmpTable from '../../presentational/RmpTable';

function UsageTypesTable(props) {
  const { edit, editIndex, usageTypes, usageTypeSearch, usageLoading } =
    useStoreState((state) => state.usage);

  const { setUsageTypeModal, setUsageType, setActive, setUsageId } =
    useStoreActions((actions) => actions);

  const hasSearchText = (value) =>
    value
      .toString()
      .toLowerCase()
      .trim()
      .includes(usageTypeSearch && usageTypeSearch.item.toLowerCase().trim());

  return (
    <Card>
      <RmpTable
        addButton={false}
        headers={[
          { columnName: 'Type', propertyName: 'type', type: 'string' },
          { columnName: 'Active', propertyName: 'active', type: 'string' },
          { columnName: 'Edit', propertyName: 'edit', disabled: true },
        ]}
        widths={[0.8, 0.1, 0.1]}
        data={(usageTypes || [])
          .filter((item) => hasSearchText(item.usageType))
          .map((item) => {
            return { type: item.usageType, active: item.active, edit: false };
          })}
        conditions={[
          {
            index: 1,
            func: (data, index) => {
              return <Active data={data} index={index} />;
            },
          },
          {
            index: 2,
            func: (data, index) => {
              return (
                <Edit
                  data={data}
                  index={index}
                  onPress={() => {
                    setUsageType(usageTypes[index].usageType);
                    setActive(usageTypes[index].active);
                    setUsageId(usageTypes[index].id);
                    setUsageTypeModal(true);
                  }}
                />
              );
            },
          },
        ]}
      />
    </Card>
  );
}

export default UsageTypesTable;
