import {
  Foundation,
  MaterialCommunityIcons,
  Octicons,
} from '@expo/vector-icons';
import { useEffect } from 'react';
import { StyleSheet, View, TouchableOpacity, Text } from 'react-native';
import { CheckBox } from 'react-native-elements';

const Active = ({ ...props }) => {
  return (
    <View
      style={{
        width: '100%',
        height: '30px',
        alignItems: 'center',
        alignContent: 'center',
        alignSelf: 'center',
        justifyContent: 'center',
      }}
    >
      <CheckBox checkedColor='#007ACC' checked={props.data} />
    </View>
  );
};

const Edit = ({ ...props }) => {
  return (
    <TouchableOpacity
      onPress={() => {
        props.onPress();
      }}
      style={{
        width: '100%',
        height: '100%',
        alignItems: 'center',
        alignContent: 'center',
        alignSelf: 'center',
        justifyContent: 'center',
      }}
    >
      <MaterialCommunityIcons
        name={'pencil-outline'}
        size={24}
        color='#007ACC'
      />
    </TouchableOpacity>
  );
};

const History = ({ ...props }) => (
  <TouchableOpacity
    onPress={() => {
      props.onPress();
    }}
    style={{
      width: '100%',
      height: '100%',
      alignItems: 'center',
      alignContent: 'center',
      alignSelf: 'center',
      justifyContent: 'center',
    }}
  >
    <Octicons name='history' size={24} color='#007ACC' />
  </TouchableOpacity>
);

const Plans = ({ ...props }) => (
  <TouchableOpacity
    onPress={() => {
      props.onPress();
    }}
    style={{
      width: '100%',
      height: '100%',
      alignItems: 'center',
      alignContent: 'center',
      alignSelf: 'center',
      justifyContent: 'center',
    }}
  >
    <Foundation name='clipboard-pencil' size={24} color='#007ACC' />
  </TouchableOpacity>
);

const Notes = ({ ...props }) => (
  <TouchableOpacity
    onPress={() => {
      props.onPress();
    }}
    style={{
      width: '100%',
      height: '100%',
      alignItems: 'center',
      alignContent: 'center',
      alignSelf: 'center',
      justifyContent: 'center',
    }}
  >
    <MaterialCommunityIcons
      name={'note'}
      size={24}
      color={props.error ? 'red' : props.data ? '#007ACC' : 'white'}
    />
  </TouchableOpacity>
);

const BlankClick = ({ ...props }) => (
  <TouchableOpacity
    onPress={() => {
      props.onPress();
    }}
    style={{
      width: '100%',
      height: '100%',
      alignItems: 'center',
      alignContent: 'center',
      alignSelf: 'center',
      justifyContent: 'center',
    }}
  >
    <Text style={styles.text}>{props.data}</Text>
  </TouchableOpacity>
);

export { Active, Edit, History, Plans, Notes, BlankClick };

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    paddingTop: 30,
    backgroundColor: '#fff',
    width: '100%',
  },
  header: { height: 50, backgroundColor: '#DCDCDC', width: '100%' },
  text: { textAlign: 'center', padding: 10, fontSize: 18 },
  dataWrapper: { marginTop: -1 },
  row: { height: 40, backgroundColor: '#F7F7F7', width: '100%' },
});
