import Image from '../Image';

function DrawerBrand(props) {
  return (
    <Image
      source={{
        uri: 'https://kioskbranding.blob.core.windows.net/images/rmpLogo.png',
      }}
      {...props}
    />
  );
}

export default DrawerBrand;
