import { useStoreActions, useStoreState } from 'easy-peasy';
import { useEffect } from 'react';

import Page from '../containers/Page';
import MaintenancePlansTable from '../containers/Table/MaintenancePlansTable';
import { Container } from '../presentational/Container';

export default function MaintenancePlans({ ...props }) {
  const { equipment } = useStoreState((state) => state);
  const { selectedEquipment } = equipment;
  const { getMaintenancePlanTableThunk } = useStoreActions(
    (actions) => actions
  );

  useEffect(() => {
    getMaintenancePlanTableThunk(selectedEquipment.id);
    props.navigation.setOptions({
      title: `Maintenance Plans for ${selectedEquipment.rmpDescription}`,
    });
  }, []);

  return (
    <Page.Protected>
      <Container>
        <MaintenancePlansTable navigation={props.navigation} />
      </Container>
    </Page.Protected>
  );
}
