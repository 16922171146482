import { action } from 'easy-peasy';

const actions = {
  setMaintenanceModal: action((state, maintenanceModal) => {
    state.maintenance.maintenanceModal = maintenanceModal;
  }),
  setMaintenanceActionsModal: action((state, maintenanceActionsModal) => {
    state.maintenance.maintenanceActionsModal = maintenanceActionsModal;
  }),
  setMaintenancePlansAction: action((state, maintenancePlans) => {
    state.maintenance.maintenancePlans = maintenancePlans;
    state &&
      state.maintenance &&
      state.maintenance.maintenancePlans &&
      state.maintenance.maintenancePlans.map((maintenancePlan) => {
        maintenancePlan.isSelected = false;
      });
  }),
  setMaintenanceActionsAction: action((state, maintenanceActions) => {
    state.maintenance.maintenanceActions = maintenanceActions;
  }),
  setSelectedMaintenancePlan: action((state, selectedMaintenancePlan) => {
    state.maintenance.selectedMaintenancePlan = selectedMaintenancePlan;
  }),
  setSelectedMaintenanceAction: action((state, selectedMaintenanceAction) => {
    state.maintenance.maintenanceAction.selectedMaintenanceAction =
      selectedMaintenanceAction;
  }),
  setEditMaintenance: action((state, edit) => {
    state.maintenance.edit = edit;
  }),
  setMaintenanceSearch: action((state, search) => {
    state.maintenance.search = search;
  }),
  setServicePackagesAction: action((state, servicePackages) => {
    state.servicePackages = servicePackages;
  }),
  setSelectedMaintenancePlanItem: action(
    (state, selectedMaintenancePlanItem) => {
      // Here we are checking the box, which is the opposite of the current state
      state.maintenance.maintenancePlans[
        selectedMaintenancePlanItem
      ].isSelected =
        !state.maintenance.maintenancePlans[selectedMaintenancePlanItem]
          .isSelected;

      // Now we add the item to the selectedMaintenancePlanItems array
      state.maintenance.selectedMaintenancePlanItems =
        state.maintenance.maintenancePlans.filter(
          (maintenancePlan) => maintenancePlan.isSelected
        );

      // Map through the selectedMaintenancePlanItems and add a property of maintenancePlanId
      state.maintenance.selectedMaintenancePlanItems.map((maintenancePlan) => {
        maintenancePlan.maintenancePlanId = maintenancePlan.id;
      });

      // Loop through the maintenance plans and add the service packages to the selected Maintenance Plan Items
      state.maintenance.selectedMaintenancePlanItems.forEach((item) => {
        // Get the id of the maintenance plan that matches in the maintenance plans array
        let id = state.maintenance.maintenancePlans.findIndex(
          (maintenancePlan) => maintenancePlan.id == item.id
        );

        //set the maintenance plan id for each selected maintenance plan item
        state &&
        state.maintenance &&
        state.maintenance.selectedMaintenanceItems &&
        state.maintenance.selectedMaintenancePlanItems[id] &&
        state.maintenance.selectedMaintenancePlanItems[id].maintenancePlanId
          ? (state.maintenance.selectedMaintenancePlanItems[id] =
              state.maintenance.maintenancePlans[id].id)
          : null;
      });
      let selectedPackages = state.maintenance.selectedMaintenancePlanItems.map(
        (maintenancePlan) => maintenancePlan.servicePackageName
      );
      let commaStr = selectedPackages.join(',');
      state.project.selectedProject.ServicePackagesToAdd = commaStr;
    }
  ),
  setMaintenancePlansLoading: action((state, loading) => {
    state.maintenance.plansLoading = loading;
  }),
  setMaintenanceActionsLoading: action((state, loading) => {
    state.maintenance.actionsLoading = loading;
  }),
  setMaintenancePlanItemsProjectCd: action((state, projectCd) => {
    state.maintenance.selectedMaintenancePlanItems.forEach((item) => {
      item.projectCd = projectCd;
    });
  }),
  setMaintenanceMap: action((state) => {
    state.maintenanceMap = state?.maintenance?.maintenancePlans.reduce(
      (map, plan) => {
        const id = `${plan.equipmentId}-${plan.componentId}-${plan.serviceId}`;
        if (!map[id]) {
          map[id] = plan;
        }
        return map;
      },
      {}
    );
  }),
  upsetMaintenanceActions: action((state, actions) => {
    const map = state?.maintenance?.maintenanceActions.reduce((map, action) => {
      if (!map[action.id]) {
        map[action.id] = action;
      }
      return map;
    }, {});

    actions.forEach((action) => {
      map[action.id] = action;
    });

    state.maintenance.maintenanceActions = Object.values(map);
  }),
  upsetMaintenanceMap: action((state, plans) => {
    state.maintenance.maintenanceActions;
    plans.forEach((plan) => {
      const id = `${plan.equipmentId}-${plan.componentId}-${plan.serviceId}`;
      state.maintenanceMap[id] = plan;
    });
    state.maintenance.maintenancePlans = Object.values(state.maintenanceMap);
  }),
  setSelectedServiceProfile: action((state, serviceProfile) => {
    state.selectedServiceProfile = serviceProfile;
  }),
  clearSelectedServiceProfile: action((state) => {
    state.selectedServiceProfile = {};
  }),
  setServiceProfileList: action((state, eqId) => {
    const tableView = state.maintenance.maintenancePlans
      .filter((plan) => plan.rootEquipmentId === eqId)
      .map((plan) => {
        const usageType = state.usage.usageTypeMap[plan?.usageType];
        const usageDisplay = `${usageType.usageType} (${plan.usageInterval})`;
        return {
          id: plan?.id || null,
          serviceId: plan.serviceId,
          serviceName: plan.serviceName,
          componentDescription: plan.componentName,
          componentId: plan.componentId,
          equipmentId: plan.equipmentId,
          usageId: usageType?.id || null,
          usageType: usageType?.usageType || null,
          usageInterval: plan?.usageInterval || null,
          usageDisplay,
          active: plan?.active || false,
          carryForwardHours: plan.carryForwardHours,
          carryForwardCycles: plan.carryForwardCycles,
          carryForwardLandings: plan.carryForwardLandings,
          carryForwardDate: plan.carryForwardDate,
        };
      });

    state.serviceProfiles = [...tableView];
  }),
};

export default actions;
