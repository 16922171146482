import { createStore } from 'easy-peasy';
import statusStore from './status';
import authStore from './auth';
import userStore from './user';
import equipmentStore from './equipment';
import usageStore from './usage';
import maintenanceStore from './maintenance';
import rolesStore from './roles';
import projectStore from './project';
import configurationStore from './configuration';
import scheduleStore from './schedule';

const store = createStore({
  ...statusStore,
  ...authStore,
  ...userStore,
  ...equipmentStore,
  ...usageStore,
  ...maintenanceStore,
  ...rolesStore,
  ...configurationStore,
  ...projectStore,
  ...scheduleStore,
});

export default store;
