import CalendarPicker from 'react-native-calendar-picker';
import { Ionicons } from '@expo/vector-icons';

import { Dimensions, Platform } from 'react-native';
import { Overlay } from '@rneui/themed';

const { width } = Dimensions.get('window');

export default function DatePicker({
  onChange,
  visible = false,
  onBackdropPress,
  ...props
}) {
  return (
    <Overlay
      visible={visible}
      onBackdropPress={onBackdropPress}
      overlayStyle={{
        width: '25%',
        height: '33%',
        alignContent: 'center',
        backgroundColor: '#FFFFFF',
      }}
    >
      <CalendarPicker
        {...props}
        nextComponent={
          <Ionicons
            name='ios-arrow-forward'
            size={20}
            color='black'
            style={{ right: width / 2.5, position: 'absolute' }}
          />
        }
        previousComponent={
          <Ionicons
            name='ios-arrow-back'
            size={20}
            color='black'
            style={{ left: width / 2.5, top: 0, position: 'absolute' }}
          />
        }
        startFromMonday={true}
        minDate={new Date(2018, 1, 1)}
        maxDate={new Date(2050, 6, 3)}
        weekdays={['Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun']}
        months={[
          'January',
          'Febraury',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December',
        ]}
        todayBackgroundColor='gray'
        selectedDayTextColor='#000000'
        scaleFactor={Platform.OS == 'web' ? 1000 : 2000}
        textStyle={{
          color: '#000000',
          zIndex: 1000,
        }}
        onDateChange={onChange}
      />
    </Overlay>
  );
}
