import { useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import moment from 'moment';
import { MaterialIcons } from '@expo/vector-icons';
import { Pressable, Row, Text } from '@cfbs/cfbsstrap-native';

import RmpTable from '../../presentational/RmpTable';
import { ProjectListModal } from '../Modal/ProjectListModal';
import Card from '../../presentational/Card';
import Icon from '../../presentational/Icon';

function ScheduleTable({ navigation, route, onRefresh, ...props }) {
  const { schedule } = useStoreState((state) => state);
  const { setScheduleEquipmentName, setScheduleSearch } = useStoreActions(
    (actions) => actions
  );

  const { configuration, scheduleMap, scheduleList, search } = schedule;
  const [isModalVisible, setShowModal] = useState(false);
  const [rowIndex, setRowIndex] = useState();

  const hasSearchText = (value) =>
    value
      .toLowerCase()
      .trim()
      .includes(search && search.item.toLowerCase().trim());

  const Click = (rowData) => {
    const item = scheduleMap[rowData.equipmentName];
    let config = {
      warning: 75,
      stop: 90,
    };
    if (configuration && configuration?.usageTypes) {
      config = configuration?.usageTypes[item.interval];
    }
    const percent = item.percentComplete;
    return (
      <Row>
        {percent >= config?.stop && (
          <Icon
            style={{ fontSize: '24px', paddingRight: '5px' }}
            Component={MaterialIcons}
            name='error'
            color='red'
          />
        )}
        {percent >= config?.warning && percent < config?.stop && (
          <Icon
            style={{ fontSize: '24px', paddingRight: '5px' }}
            Component={MaterialIcons}
            name='warning'
            color='yellow'
          />
        )}
        <Pressable
          style={{ paddingTop: '1px' }}
          onPress={() => {
            setScheduleEquipmentName({
              equipmentName: rowData.equipmentName,
              rmpDescription: rowData.equipment,
            });
            navigation.navigate('Details', {
              token: route?.params?.token,
            });
          }}
        >
          <Text
            style={{
              fontSize: 18,
              color: '#0000ee',
              fontFamily: 'Barlow_400Regular',
              textDecoration: 'underline',
              textAlign: 'left',
              justifyContent: 'left',
              alignItems: 'left',
            }}
          >
            {rowData.equipment}
          </Text>
        </Pressable>
      </Row>
    );
  };

  const Scheduled = ({ data, index }) => {
    if (data.length > 0) {
      return (
        <Pressable
          onPress={() => {
            setRowIndex(index);
            setShowModal(true);
          }}
        >
          <Text
            style={{
              fontSize: 18,
              color: '#0000ee',
              fontFamily: 'Barlow_400Regular',
              textDecoration: 'underline',
              textAlign: 'left',
              justifyContent: 'left',
              alignItems: 'left',
            }}
          >
            View
          </Text>
        </Pressable>
      );
    }
  };

  return (
    <Card>
      <ProjectListModal
        projects={
          scheduleList &&
          scheduleList[rowIndex] &&
          scheduleList[rowIndex].projects
        }
        isVisible={isModalVisible}
        setVisible={setShowModal}
      />
      <RmpTable
        text={'Schedule Summary'}
        addButton={false}
        onChange={(search) => {
          setScheduleSearch(search);
        }}
        onClear={() => {
          setScheduleSearch({ item: '' });
        }}
        onRefresh={onRefresh}
        value={search?.item}
        headers={[
          {
            columnName: 'Equipment',
            propertyName: 'equipment',
            type: 'string',
          },
          {
            columnName: 'Equipment Id',
            propertyName: 'equipmentName',
            type: 'string',
          },
          {
            columnName: 'Next Service Item (Due Date)',
            propertyName: 'serviceName',
            type: 'string',
          },
          {
            columnName: 'Forecast Date',
            propertyName: 'forecastDate',
            type: 'date',
          },
          {
            columnName: 'Projects',
            propertyName: 'projects',
            type: 'other',
            disabled: true,
          },
        ]}
        widths={[0.2, 0.2, 0.4, 0.12, 0.08]}
        data={(scheduleList || [])
          .sort((a, b) => {
            const percentA = parseInt(a.percentComplete);
            const percentB = parseInt(b.percentComplete);
            if (percentA < percentB) {
              return 1;
            }
            if (percentA > percentB) {
              return -1;
            }
            return 0;
          })
          .filter(
            (equipment) =>
              hasSearchText(equipment.rmpDescription) ||
              hasSearchText(equipment.equipmentName) ||
              hasSearchText(equipment.serviceName) ||
              hasSearchText(
                moment.utc(equipment.forecastDate).format('MM/DD/YYYY')
              ) ||
              hasSearchText(moment.utc(equipment.dueDate).format('MM/DD/YYYY'))
          )
          .map((equipment) => {
            return {
              equipment: equipment.rmpDescription,
              equipmentName: equipment.equipmentName,
              serviceName: `${equipment.serviceName} (${moment
                .utc(equipment.dueDate)
                .format('MM/DD/YYYY')})`,
              forecastDate: equipment.forecastDate
                ? moment.utc(equipment.forecastDate).format('MM/DD/YYYY')
                : 'N/A',
              projects: equipment.projects,
            };
          })}
        conditions={[
          {
            index: 0,
            func: (data, index, rowData) => {
              return Click(rowData);
            },
          },
          {
            index: 4,
            func: (data, index) => {
              return <Scheduled data={data} index={index} />;
            },
          },
        ]}
      />
    </Card>
  );
}

export default ScheduleTable;
