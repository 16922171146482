import { useStoreActions, useStoreState } from 'easy-peasy';

import Page from '../containers/Page';
import UsageEquipmentTable from '../containers/Table/UsageEquipmentTable';
import { Container } from '../presentational/Container';

export default function UsageEquipment({ navigation, route }) {
  const {
    usage: { equipmentId },
  } = useStoreState((state) => state);
  const { getUsageThunk } = useStoreActions((actions) => actions);

  const loadData = () => {
    getUsageThunk(equipmentId);
  };

  return (
    <Page.Protected route={route}>
      <Container>
        <UsageEquipmentTable
          navigation={navigation}
          route={route}
          onRefresh={loadData}
        />
      </Container>
    </Page.Protected>
  );
}
