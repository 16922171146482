import { acmAxios } from '.';

async function getUtilizationAPICall(monthCount) {
  const response = await acmAxios({
    method: 'post',
    url: `/getUtilization`,
    data: { monthCount },
  });
  return response.data;
}

export { getUtilizationAPICall };
