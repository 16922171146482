import { thunk } from 'easy-peasy';
import { Toast } from 'react-native-toast-notifications';

import { getRolesAPICall } from '../../api/roles';

const thunks = {
  getRolesThunk: thunk(async (actions) => {
    actions.setLoadings({ roles: true });
    try {
      const list = await getRolesAPICall();
      actions.setRolesAction(list);
    } catch (e) {
      let message = e.message || '';
      e.response && e.response.data && (message = e.response.data.message);
      Toast.show(message, {
        type: 'danger',
        placement: 'bottom',
        duration: 5000,
        offset: 30,
        animationType: 'slide-in',
      });
    }
    actions.setLoadings({
      roles: false,
    });
  }),
};

export default thunks;
