import { Button } from '@cfbs/cfbsstrap-native';
export default function SecondaryButton({ children, ...props }) {
  return (
    <Button
      style={{
        backgroundColor: 'white',
        opacity: props.disabled ? '0.5' : '1',
      }}
      {...props}
    >
      {children}
    </Button>
  );
}
