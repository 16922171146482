import { useStoreState, useStoreActions } from 'easy-peasy';
import { Active, History, Plans } from './TableUtils';
import RmpTable from '../../presentational/RmpTable';
import Card from '../../presentational/Card';

function EquipmentPageTable({ navigation }) {
  const { equipmentData, tableSearch } = useStoreState(
    (state) => state.equipment
  );

  const {
    setMaintenancePlansAction,
    setUsageAction,
    setSelectedEquipment,
    setUsageEquipmentId,
    setEquipmentDataSearch,
  } = useStoreActions((actions) => actions);

  const hasSearchText = (value) => {
    return value
      .toLowerCase()
      .trim()
      .includes(tableSearch && tableSearch.item.toLowerCase().trim());
  };

  return (
    <Card>
      <RmpTable
        addButton={false}
        onChange={(search) => {
          setEquipmentDataSearch(search);
        }}
        onClear={() => {
          setEquipmentDataSearch({ item: '' });
        }}
        value={tableSearch?.item}
        headers={[
          {
            columnName: 'Equipment',
            propertyName: 'equipment',
            type: 'string',
          },
          { columnName: 'Name', propertyName: 'equipmentName', type: 'string' },
          { columnName: 'Serial No', propertyName: 'serialNo', type: 'string' },
          {
            columnName: 'Description',
            propertyName: 'description',
            type: 'string',
          },
          { columnName: 'Active', propertyName: 'active', type: 'string' },
          { columnName: 'Plans', propertyName: 'plans', disabled: true },
          { columnName: 'History', propertyName: 'history', disabled: true },
        ]}
        widths={[0.25, 0.2, 0.2, 0.2, 0.05, 0.05, 0.05]}
        data={(equipmentData || [])
          .filter(
            (equipment) =>
              hasSearchText(equipment.rmpDescription) ||
              hasSearchText(equipment.equipmentCd) ||
              hasSearchText(equipment.equipmentName) ||
              hasSearchText(equipment.equipmentSerialNo) ||
              hasSearchText(equipment.equipmentDescription)
          )
          .map((equipment) => {
            return {
              equipment: `${equipment.rmpDescription} (${equipment.equipmentCd})`,
              equipmentName: equipment.equipmentName,
              serialNo: equipment.equipmentSerialNo,
              description: equipment.equipmentDescription,
              active: equipment.active,
              plans: 'Plans',
              history: equipment.equipmentId,
            };
          })}
        conditions={[
          {
            index: 4,
            func: (data, index) => {
              return <Active data={data} index={index} />;
            },
          },
          {
            index: 5,
            func: (data, index) => {
              return (
                <Plans
                  data={data}
                  index={index}
                  onPress={() => {
                    setMaintenancePlansAction(null);
                    setSelectedEquipment(equipmentData[index]);
                    navigation.navigate('Maintenance Plans', {
                      id: equipmentData[index].id,
                    });
                  }}
                />
              );
            },
          },
          {
            index: 6,
            func: (data, index) => {
              return (
                <History
                  data={data}
                  index={index}
                  onPress={() => {
                    setUsageAction([]);
                    setUsageEquipmentId(equipmentData[index].id);
                    setSelectedEquipment(equipmentData[index]);
                    navigation.navigate('Usage History');
                  }}
                />
              );
            },
          },
        ]}
      />
    </Card>
  );
}

export default EquipmentPageTable;
