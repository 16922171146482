import { useStoreActions, useStoreState } from 'easy-peasy';
import { useEffect } from 'react';

import Page from '../containers/Page';
import UsageTypeModal from '../containers/Modal/UsageTypeModal';
import UsageTypesTable from '../containers/Table/UsageTypesTable';
import { Container } from '../presentational/Container';

export default function UsageTypes() {
  const {
    usage: { usageTypes, usageTypeSearch },
  } = useStoreState((state) => state);
  const { getUsageTypesThunk, setUsageTypeSearch } = useStoreActions(
    (actions) => actions
  );

  useEffect(() => {
    function getEquipments() {
      getUsageTypesThunk();
    }

    !usageTypes && getEquipments();
  }, [usageTypes]);

  return (
    <Page.Protected>
      <Container>
        <UsageTypeModal />
        <UsageTypesTable />
      </Container>
    </Page.Protected>
  );
}
