import { thunk } from 'easy-peasy';
import { Toast } from 'react-native-toast-notifications';
import { getConfigurationAPI } from '../../api/configuration';

const thunks = {
  getConfigurationThunk: thunk(async (actions) => {
    actions.setLoadings({ configuration: true });

    try {
      const list = await getConfigurationAPI();
      actions.addConfigurationData(list);
    } catch (e) {
      let message = e.message || '';
      e.response && e.response.data && (message = e.response.data.message);
      Toast.show(message, {
        type: 'danger',
        placement: 'bottom',
        duration: 5000,
        offset: 30,
        animationType: 'slide-in',
      });
    }

    actions.setLoadings({
      configuration: false,
    });
  }),
};

export default thunks;
