import { useStoreState } from 'easy-peasy';
import { ActivityIndicator, ScrollView } from 'react-native';
import { StatusBar } from 'expo-status-bar';
import { useEffect, useState } from 'react';
import { Center, Text } from '@cfbs/cfbsstrap-native';

function Page({ children, ...props }) {
  const { loadings } = useStoreState((state) => state);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(Object.values(loadings).includes(true));
  }, [loadings]);

  return (
    <ScrollView {...props}>
      <StatusBar style='auto' />
      {isLoading ? (
        <Center>
          <Text
            style={{
              fontFamily: 'Barlow_600SemiBold',
              fontSize: 30,
            }}
          >
            Loading...
          </Text>
          <ActivityIndicator size='large' color='#0088ce' />
        </Center>
      ) : (
        children
      )}
    </ScrollView>
  );
}

export default Page;
