import { useStoreActions, useStoreState } from 'easy-peasy';
import EntypoIcon from 'react-native-vector-icons/Entypo';
import { Col, Row } from '@cfbs/cfbsstrap-native';
import { useEffect, useState } from 'react';

import RMPModal from '../../presentational/Modal';
import Input from '../../presentational/Input';
import Switch from '../../presentational/Switch';
import Icon from '../../presentational/Icon';

export default function NameProjectModal() {
  const [isQuote, setIsQuote] = useState(true);
  const { schedule } = useStoreState((state) => state);
  const {
    projectDescription,
    projectVisible,
    detailList,
    dropdowns,
    workOrderQuote,
  } = schedule;

  const {
    setScheduleProjectModalVisible,
    setScheduleProjectDescription,
    addScheduleProjectThunk,
    addScheduleWorkOrderQuoteThunk,
    setWorkOrderQuote,
  } = useStoreActions((actions) => actions);

  useEffect(() => {
    const selected = detailList.filter((item) => item.selected);
    const hasCustomer = selected.some((item) => item.customerCd);
    const isWOQRequired = selected.some((item) => item.woqRequired);
    if (hasCustomer && isWOQRequired) {
      setIsQuote(true);
    } else {
      setIsQuote(false);
    }
  }, [detailList]);

  return (
    <RMPModal
      onSubmit={async () => {
        if (isQuote) {
          await addScheduleWorkOrderQuoteThunk();
        } else {
          await addScheduleProjectThunk();
        }
        setScheduleProjectModalVisible(false);
      }}
      open={projectVisible}
      headerText={'Create'}
      onClose={() => {
        setScheduleProjectModalVisible(false);
      }}
      children={
        <>
          <Row>
            <Col xs={12}>
              <Switch
                label='Create Quote'
                onValueChange={() => setIsQuote(!isQuote)}
                value={isQuote}
              />
            </Col>
          </Row>
          {isQuote && (
            <>
              <Row>
                <Col xs={6}>
                  <Input
                    label='Division'
                    placeholder='Select Division'
                    onSelect={(selectedItem) => {
                      setWorkOrderQuote({
                        ...workOrderQuote,
                        division: selectedItem.value,
                      });
                    }}
                    value={workOrderQuote.division}
                    dropdown
                    search
                    selectedItem={(item) => `${item.label} - ${item.value}`}
                    rowTextForSelection={(item) =>
                      `${item.label} - ${item.value}`
                    }
                    dropdownChoices={dropdowns.division}
                    searchInputStyle={{
                      padding: '0 10px',
                      fontFamily: 'Barlow_400Regular',
                      color: '#007ACC',
                      borderBottomWidth: 1,
                    }}
                    searchInputTxtStyle={{
                      color: '#007ACC',
                      fontFamily: 'Barlow_400Regular',
                    }}
                    renderSearchInputLeftIcon={() => {
                      return (
                        <Icon Component={EntypoIcon} name='magnifying-glass' />
                      );
                    }}
                  />
                </Col>
                <Col xs={6}>
                  <Input
                    label='Component'
                    placeholder='Select Component'
                    onSelect={(selectedItem) => {
                      setWorkOrderQuote({
                        ...workOrderQuote,
                        component: selectedItem.value,
                      });
                    }}
                    value={workOrderQuote.component}
                    dropdown
                    search
                    selectedItem={(item) => `${item.label} - ${item.value}`}
                    rowTextForSelection={(item) =>
                      `${item.label} - ${item.value}`
                    }
                    dropdownChoices={dropdowns.component}
                    searchInputStyle={{
                      padding: '0 10px',
                      fontFamily: 'Barlow_400Regular',
                      color: '#007ACC',
                      borderBottomWidth: 1,
                    }}
                    searchInputTxtStyle={{
                      color: '#007ACC',
                      fontFamily: 'Barlow_400Regular',
                    }}
                    renderSearchInputLeftIcon={() => {
                      return (
                        <Icon Component={EntypoIcon} name='magnifying-glass' />
                      );
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Input
                    label='Description'
                    onChangeText={(text) =>
                      setWorkOrderQuote({
                        ...workOrderQuote,
                        description: text,
                      })
                    }
                    value={workOrderQuote.description}
                  />
                </Col>
              </Row>
            </>
          )}
          {!isQuote && (
            <>
              <Row>
                <Col xs={12}>
                  <Input
                    label='Description'
                    onChangeText={(text) => setScheduleProjectDescription(text)}
                    value={projectDescription}
                  />
                </Col>
              </Row>
            </>
          )}
        </>
      }
    />
  );
}
