import { Text, Switch as RNSwitch } from '@rneui/themed';
import styled from 'styled-components/native';
import { remToDp } from '../../utilities/responsive';

const Container = styled.View`
  flex-direction: row;
  align-items: center;
`;

const SText = styled(Text)`
  margin-left: 10px;
  font-size: ${remToDp(1)};
  font-family: Barlow_400Regular;
`;

function Switch({ label, ...props }) {
  return (
    <Container>
      <RNSwitch {...props} />
      {label && <SText>{label}</SText>}
    </Container>
  );
}

export default Switch;
