import { acmAxios } from '.';

async function getAcmEquipmentsAPICall() {
  const response = await acmAxios({
    method: 'get',
    url: `/getAcumaticaEquipment`,
  });
  return response.data;
}

async function getEquipmentsAPICall() {
  const response = await acmAxios({
    method: 'get',
    url: `/getTenantEquipmentStatus`,
  });
  return response.data;
}

async function getEquipmentUsageAPICall(equipmentId) {
  let url = '/getEquipmentUsage';
  if (equipmentId) {
    url = `${url}?equipmentId=${equipmentId}`;
  }
  const response = await acmAxios({
    method: 'get',
    url,
  });
  return response.data;
}

async function getEquipmentTableAPICall() {
  const response = await acmAxios({
    method: 'get',
    url: `/getEquipment`,
  });
  return response.data;
}

export {
  getEquipmentsAPICall,
  getEquipmentUsageAPICall,
  getEquipmentTableAPICall,
  getAcmEquipmentsAPICall,
};
