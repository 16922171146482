import { thunk } from 'easy-peasy';
import { Toast } from 'react-native-toast-notifications';
import {
  getEquipmentTableAPICall,
  getAcmEquipmentsAPICall,
} from '../../api/equipment';
import { getUtilizationAPICall } from '../../api/utilization';

const thunks = {
  getUsageThunk: thunk(async (actions, payload, helpers) => {
    actions.setLoadings({ getUsage: true });
    try {
      const monthCount = helpers.getState().usage.averageMonthCount;
      const utilization = await getUtilizationAPICall(monthCount);
      const acmEquipment = await getAcmEquipmentsAPICall();
      actions.setAcmEquipment({ equipment: acmEquipment.value, utilization });
    } catch (e) {
      let message = e.message || '';
      e.response && e.response.data && (message = e.response.data.message);
      Toast.show(message, {
        type: 'danger',
        placement: 'bottom',
        duration: 5000,
        offset: 30,
        animationType: 'slide-in',
      });
    }
    actions.setLoadings({
      getUsage: false,
    });
  }),
  getEquipmentsTableThunk: thunk(async (actions) => {
    actions.setLoadings({ equipmentTable: true });
    try {
      const list = await getEquipmentTableAPICall();
      actions.setEquipmentData(list);
    } catch (e) {
      let message = e.message || '';
      e.response && e.response.data && (message = e.response.data.message);
      Toast.show(message, {
        type: 'danger',
        placement: 'bottom',
        duration: 5000,
        offset: 30,
        animationType: 'slide-in',
      });
    }
    actions.setLoadings({
      equipmentTable: false,
    });
  }),
};

export default thunks;
