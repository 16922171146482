import { useStoreActions, useStoreState } from 'easy-peasy';
import { Active, Edit, Plans } from './TableUtils';
import Card from '../../presentational/Card';
import RmpTable from '../../presentational/RmpTable';

function MaintenancePlansTable({ ...props }) {
  const { serviceProfiles, maintenance, equipment } = useStoreState(
    (state) => state
  );
  const {
    setSelectedServiceProfile,
    setMaintenanceActionsAction,
    setMaintenanceSearch,
  } = useStoreActions((actions) => actions);

  const hasSearchText = (value) =>
    value
      .toLowerCase()
      .trim()
      .includes(
        maintenance?.search && maintenance?.search.item.toLowerCase().trim()
      );

  return (
    <Card>
      <RmpTable
        addButton={false}
        onChange={(search) => {
          setMaintenanceSearch(search);
        }}
        onClear={() => {
          setMaintenanceSearch({ item: '' });
        }}
        value={maintenance?.search?.item}
        headers={[
          {
            columnName: 'Service Profile/Package',
            propertyName: 'description',
            type: 'string',
          },
          {
            columnName: 'Equipment',
            propertyName: 'equipmentId',
            type: 'string',
          },
          {
            columnName: 'Component Description',
            propertyName: 'component',
            type: 'string',
          },
          {
            columnName: 'Usage (Interval)',
            propertyName: 'usage',
            type: 'string',
          },
          { columnName: 'Actions', propertyName: 'actions', disabled: true },
          { columnName: 'Active', propertyName: 'active', type: 'string' },
        ]}
        widths={[0.4, 0.15, 0.15, 0.2, 0.05, 0.05]}
        data={(serviceProfiles || [])
          .sort((a, b) => {
            const nameA = a.equipmentId.toUpperCase();
            const nameB = b.equipmentId.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          })
          .filter(
            (profile) =>
              hasSearchText(profile?.serviceName) ||
              hasSearchText(profile.equipmentId) ||
              hasSearchText(profile.componentDescription) ||
              hasSearchText(profile.usageDisplay)
          )
          .map((profile) => {
            return {
              description: profile?.serviceName,
              equipmentId: profile.equipmentId,
              component: profile.componentDescription,
              usage: profile.usageDisplay,
              actions: false,
              active: profile.active,
            };
          })}
        conditions={[
          {
            index: 4,
            func: (data, index) => {
              return (
                <Plans
                  data={data}
                  index={index}
                  onPress={() => {
                    setMaintenanceActionsAction(null);
                    setSelectedServiceProfile(serviceProfiles[index]);
                    props.navigation.navigate('Maintenance Actions', {
                      id: serviceProfiles[index].id,
                      cd: serviceProfiles[index].equipmentId,
                    });
                  }}
                />
              );
            },
          },
          {
            index: 5,
            func: (data, index) => {
              return <Active data={data} index={index} />;
            },
          },
        ]}
      />
    </Card>
  );
}

export default MaintenancePlansTable;
