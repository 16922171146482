import { action } from 'easy-peasy';

const actions = {
  setLoginAction: action((state, login) => {
    state.login = {
      ...state.login,
      ...login,
    };
  }),
  setErpLoginAction: action((state, login) => {
    state.erpLogin = {
      ...state.erpLogin,
      ...login,
    };
  }),
  setErpLoggedIn: action((state, isAuthenticated) => {
    state.erpIsAuthenticated = isAuthenticated;
  }),
  setLoggedIn: action((state, isAuthenticated) => {
    state.isAuthenticated = isAuthenticated;
  }),
  setIsNested: action((state, isNested) => {
    state.isNested = isNested;
  }),
};

export default actions;
