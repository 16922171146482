const state = {
  login: {
    email: '',
    password: '',
  },
  erpLogin: {
    name: '',
    password: '',
  },
  erpIsAuthenticated: false,
  isAuthenticated: false,
  isNested: true,
};

export default state;
