import { acmAxios } from '.';

export async function getMaintenancePlansAPICall() {
  const response = await acmAxios({
    method: 'get',
    url: `/getMaintenancePlan`,
  });
  return response.data;
}

export async function getMaintenanceActionsAPICall(eqId, id) {
  const response = await acmAxios({
    method: 'get',
    url: `/getMaintenanceAction?equipmentId=${eqId}&maintenancePlanId=${parseInt(
      id
    )}`,
  });
  return response.data;
}

export async function upsertMaintenancePlanProjectAPICall(data) {
  const response = await acmAxios({
    method: 'post',
    url: `/upsertMaintenancePlanProject`,
    data: data,
  });
  return response.data;
}

export async function upsertMaintenanceActionAPICall(data) {
  const response = await acmAxios({
    method: 'post',
    url: `/upsertMaintenanceAction`,
    data: data,
  });
  return response.data;
}

export async function getServicePackagesAPICall() {
  const response = await acmAxios({
    method: 'get',
    url: `/getServicePackage`,
  });
  return response.data;
}
