import { StoreProvider } from 'easy-peasy';
import { ToastProvider } from 'react-native-toast-notifications';

import { useFonts } from 'expo-font';
import {
  Barlow_100Thin,
  Barlow_100Thin_Italic,
  Barlow_200ExtraLight,
  Barlow_200ExtraLight_Italic,
  Barlow_300Light,
  Barlow_300Light_Italic,
  Barlow_400Regular,
  Barlow_400Regular_Italic,
  Barlow_500Medium,
  Barlow_500Medium_Italic,
  Barlow_600SemiBold,
  Barlow_600SemiBold_Italic,
  Barlow_700Bold,
  Barlow_700Bold_Italic,
  Barlow_800ExtraBold,
  Barlow_800ExtraBold_Italic,
  Barlow_900Black,
  Barlow_900Black_Italic,
} from '@expo-google-fonts/barlow';

import AppDrawerNavigator from './src/components/navigation/AppDrawerNavigator';
import store from './src/store';
import '././logbox/ignore';
import { axiosClient, acmAxios } from './src/api';
import { axiosInterceptor } from './src/utilities/api';
import { ERP_TOKEN_NAME, KIOSK_TOKEN_NAME } from './src/utilities/constants';

export default function App() {
  axiosInterceptor(axiosClient, store, KIOSK_TOKEN_NAME);
  axiosInterceptor(acmAxios, store, ERP_TOKEN_NAME);
  const [fontsLoaded] = useFonts({
    Barlow_100Thin,
    Barlow_100Thin_Italic,
    Barlow_200ExtraLight,
    Barlow_200ExtraLight_Italic,
    Barlow_300Light,
    Barlow_300Light_Italic,
    Barlow_400Regular,
    Barlow_400Regular_Italic,
    Barlow_500Medium,
    Barlow_500Medium_Italic,
    Barlow_600SemiBold,
    Barlow_600SemiBold_Italic,
    Barlow_700Bold,
    Barlow_700Bold_Italic,
    Barlow_800ExtraBold,
    Barlow_800ExtraBold_Italic,
    Barlow_900Black,
    Barlow_900Black_Italic,
  });

  return (
    <StoreProvider store={store}>
      <ToastProvider>{fontsLoaded && <AppDrawerNavigator />}</ToastProvider>
    </StoreProvider>
  );
}
