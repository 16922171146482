import { useStoreActions, useStoreState } from 'easy-peasy';
import { useCallback } from 'react';
import { useFocusEffect } from '@react-navigation/native';

import Page from '../containers/Page';
import UsageHistoryTable from '../containers/Table/UsageHistoryTable';
import EnterUsageModal from '../containers/Modal/EnterUsageModal';
import { Container } from '../presentational/Container';

export default function UsageHistory({ navigation, route }) {
  const {
    usage: { equipmentId },
    isAuthenticated,
    erpIsAuthenticated,
  } = useStoreState((state) => state);
  const { getUsageThunk, setEnterUsageModal } = useStoreActions(
    (actions) => actions
  );

  const loadData = () => {
    getUsageThunk(equipmentId);
  };

  useFocusEffect(
    useCallback(() => {
      if (route.params && route.params.openModal === 'true') {
        setEnterUsageModal(true);
      }
      loadData();
    }, [equipmentId, route.params, isAuthenticated, erpIsAuthenticated])
  );

  return (
    <Page.Protected route={route}>
      <Container>
        <EnterUsageModal />
        <UsageHistoryTable
          navigation={navigation}
          route={route}
          onRefresh={loadData}
        />
      </Container>
    </Page.Protected>
  );
}
