import { useStoreActions, useStoreState } from 'easy-peasy';
import { useEffect } from 'react';
import { useIsFocused } from '@react-navigation/native';

import Page from '../containers/Page';
import ScheduleTable from '../containers/Table/ScheduleTable';
import { Container } from '../presentational/Container';

export default function Schedule({ navigation, route }) {
  const { isAuthenticated, erpIsAuthenticated } = useStoreState(
    (state) => state
  );
  const { loadSchedulesThunk } = useStoreActions((actions) => actions);
  const isFocused = useIsFocused();

  useEffect(() => {
    if (isFocused) {
      if (isAuthenticated && erpIsAuthenticated) {
        loadSchedulesThunk();
      }
    }
  }, [route.params, isFocused, isAuthenticated, erpIsAuthenticated]);

  return (
    <Page.Protected route={route}>
      <Container>
        <ScheduleTable
          navigation={navigation}
          route={route}
          onRefresh={loadSchedulesThunk}
        />
      </Container>
    </Page.Protected>
  );
}
