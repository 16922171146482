import { useStoreActions, useStoreState } from 'easy-peasy';
import moment from 'moment';
import { Pressable, Text, Row, Col } from '@cfbs/cfbsstrap-native';

import Card from '../../presentational/Card';
import RmpTable from '../../presentational/RmpTable';

function UsageHistoryTable(props) {
  const { usage } = useStoreState((state) => state);
  const { search, usageLoading, acmEquipment } = usage;
  const {
    setEnterUsageModal,
    setSelectedUsage,
    setUsageSearch,
    setUsageEquipmentId,
    setUsageEquipmentDescription,
    setUsageLogId,
    setUsageLogDescription,
    setAverageMonthCount,
  } = useStoreActions((actions) => actions);

  const Open = () => {
    setSelectedUsage({
      equipmentUsageId: null,
      equipmentId: null,
      hours: '',
      cycles: '',
      landings: '',
      usageDate: moment.utc().format('MM/DD/YYYY'),
      notes: '',
    });
    setEnterUsageModal(true);
  };

  const Close = () => {
    setEnterUsageModal(false);
  };

  const averageSettings = (count) => {
    setAverageMonthCount(count);
    props.onRefresh();
  };

  const hasSearchText = (value) =>
    value
      .toString()
      .toLowerCase()
      .trim()
      .includes(search && search.item.toLowerCase().trim());

  const openEquipment = (rowData) => {
    setUsageEquipmentId(rowData.EquipmentCd);
    setUsageEquipmentDescription(rowData.Equipment);
    props.navigation.navigate('Equipment', {
      token: props.route?.params?.token,
    });
  };

  const openLog = (rowData) => {
    setUsageLogId(rowData.EquipmentCd);
    setUsageLogDescription(rowData.Equipment);
    props.navigation.navigate('Log', {
      token: props.route?.params?.token,
    });
  };

  const Click = (rowData, action, linkText = '') => (
    <Pressable
      onPress={() => {
        action();
      }}
    >
      <Text
        style={{
          fontSize: 18,
          color: '#0000ee',
          fontFamily: 'Barlow_400Regular',
          textDecoration: 'underline',
          textAlign: 'left',
          justifyContent: 'left',
          alignItems: 'left',
        }}
      >
        {linkText}
      </Text>
    </Pressable>
  );

  const Hours = (rowData) => (
    <>
      <Row>
        <Col style={{ paddingTop: '4px', paddingBottom: '4px' }} xs={6}>
          Monthly
        </Col>
        <Col
          style={{
            paddingTop: '4px',
            paddingBottom: '4px',
            textAlign: 'right',
          }}
          xs={6}
        >
          {rowData.logs.monthly}
        </Col>
      </Row>
      <Row>
        <Col style={{ paddingTop: '4px', paddingBottom: '4px' }} xs={6}>
          Average
        </Col>
        <Col
          style={{
            paddingTop: '4px',
            paddingBottom: '4px',
            textAlign: 'right',
          }}
          xs={6}
        >
          {rowData.logs.average}
        </Col>
      </Row>
      <Row>
        <Col style={{ paddingTop: '4px', paddingBottom: '4px' }} xs={6}>
          Forecast
        </Col>
        <Col
          style={{
            paddingTop: '4px',
            paddingBottom: '4px',
            textAlign: 'right',
          }}
          xs={6}
        >
          {rowData.logs.forecast}
        </Col>
      </Row>
    </>
  );

  return (
    <Card title='Equipment' isLoading={usageLoading}>
      <RmpTable
        text={`Usage Summary`}
        addButton={true}
        addButtonText='Add Usage'
        settingsButton={true}
        onSettingsPress={averageSettings}
        onRefresh={props.onRefresh}
        onPress={() => Open()}
        onDismiss={() => Close()}
        onChange={(search) => {
          setUsageSearch(search);
        }}
        onClear={() => {
          setUsageSearch({ item: '' });
        }}
        value={search?.item}
        headers={[
          {
            columnName: 'Equipment',
            propertyName: 'Equipment',
            type: 'string',
          },
          {
            columnName: 'Current Hobbs',
            propertyName: 'CurrentHobbs',
            type: 'number',
          },
          {
            columnName: 'Hours',
            propertyName: 'Hours',
            type: 'number',
          },
          {
            columnName: 'Total Hours',
            propertyName: 'TotalHours',
            type: 'number',
          },
          { columnName: 'Cycles', propertyName: 'Cycles', type: 'number' },
          {
            columnName: 'Ratio',
            propertyName: 'Ratio',
            type: 'number',
          },
          { columnName: 'Landings', propertyName: 'Landings', type: 'number' },
          {
            columnName: 'Usage Date',
            propertyName: 'LastUsageDate',
            type: 'date',
          },
          { columnName: '', propertyName: '', disabled: true },
          { columnName: '', propertyName: '', disabled: true },
        ]}
        widths={[0.18, 0.08, 0.22, 0.08, 0.08, 0.08, 0.08, 0.08, 0.06, 0.06]}
        data={(acmEquipment || [])
          .filter(
            (equipment) =>
              hasSearchText(
                `${equipment.ComponentDescription} (${equipment.EquipmentCd})`
              ) ||
              hasSearchText(
                parseFloat(equipment.CurrentHobbs || 0).toFixed(2)
              ) ||
              hasSearchText(
                parseFloat(equipment.totalMonthHours || 0).toFixed(2)
              ) ||
              hasSearchText(parseFloat(equipment.average || 0).toFixed(2)) ||
              hasSearchText(
                parseFloat(equipment.ForecastedHours || 0).toFixed(2)
              ) ||
              hasSearchText(parseFloat(equipment.TotalHours || 0).toFixed(2)) ||
              hasSearchText(parseFloat(equipment.Cycles || 0).toFixed(2)) ||
              hasSearchText(
                `${parseFloat(
                  parseFloat(equipment.TotalHours || 0).toFixed(2) /
                    parseFloat(equipment.Cycles || 0).toFixed(2)
                ).toFixed(2)}`
              ) ||
              hasSearchText(parseFloat(equipment.Landings || 0).toFixed(2)) ||
              hasSearchText(
                equipment.LastUsageDate
                  ? moment.utc(equipment.LastUsageDate).format('MM/DD/YYYY')
                  : 'N/A'
              )
          )
          .map((item) => {
            const ratio =
              parseFloat(item.Cycles || 0).toFixed(2) ===
              parseFloat(0).toFixed(2)
                ? parseFloat(0).toFixed(2)
                : parseFloat(
                    parseFloat(item.TotalHours || 0).toFixed(2) /
                      parseFloat(item.Cycles || 0).toFixed(2)
                  ).toFixed(2);
            return {
              Equipment: `${item.ComponentDescription} (${item.EquipmentCd})`,
              CurrentHobbs: parseFloat(item.CurrentHobbs || 0).toFixed(2),
              Hours: parseFloat(item.totalMonthHours || 0).toFixed(2),
              TotalHours: parseFloat(item.TotalHours || 0).toFixed(2),
              Cycles: parseFloat(item.Cycles || 0).toFixed(2),
              Ratio: ratio,
              Landings: parseFloat(item.Landings || 0).toFixed(2),
              Date: item.LastUsageDate
                ? moment.utc(item.LastUsageDate).format('MM/DD/YYYY')
                : 'N/A',
              EquipmentCd: item.EquipmentCd,
              logs: {
                monthly: parseFloat(item.totalMonthHours || 0).toFixed(2),
                average: parseFloat(item.average || 0).toFixed(2),
                forecast: parseFloat(item.ForecastedHours || 0).toFixed(2),
              },
            };
          })}
        conditions={[
          {
            index: 2,
            func: (data, index, rowData) => {
              return Hours(rowData);
            },
          },
          {
            index: 8,
            func: (data, index, rowData) => {
              return Click(rowData, () => openEquipment(rowData), 'Equipment');
            },
          },
          {
            index: 9,
            func: (data, index, rowData) => {
              return Click(rowData, () => openLog(rowData), 'Log');
            },
          },
        ]}
      />
    </Card>
  );
}

export default UsageHistoryTable;
