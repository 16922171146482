import { useState } from 'react';
import styled from 'styled-components/native';
import Feather from 'react-native-vector-icons/Feather';
import Icons from 'react-native-vector-icons/Ionicons';
import { Row, Col, Text, Menu } from '@cfbs/cfbsstrap-native';

import SearchBar from '../presentational/SearchBar';
import SecondaryButton from './Button/SecondaryButton';
import Icon from './Icon';
import Center from './Center';

const SRow = styled(Row)`
  align-items: center;
  padding: 0;
`;

const AddButton = (props) => (
  <SecondaryButton disabled={props.disabled} onPress={() => props.onPress()}>
    <Icon Component={Feather} name='plus' color='black' />
  </SecondaryButton>
);

const RefreshButton = (props) => (
  <SecondaryButton disabled={props.disabled} onPress={() => props.onPress()}>
    <Icon Component={Icons} name='refresh-sharp' color='black' />
  </SecondaryButton>
);

const SettingsButton = (props) => {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <Menu.Toggle
      style={{ paddingLeft: '12px' }}
      Icon={Icons}
      IconName='settings-sharp'
      showMenu={showMenu}
      onShowMenu={() => setShowMenu(true)}
      onHideMenu={() => setShowMenu(false)}
    >
      <Menu.Item
        onPress={() => {
          setShowMenu(false);
          props.onPress(1);
        }}
      >
        1 Month Average
      </Menu.Item>
      <Menu.Item
        onPress={() => {
          setShowMenu(false);
          props.onPress(3);
        }}
      >
        3 Month Average
      </Menu.Item>
      <Menu.Item
        onPress={() => {
          setShowMenu(false);
          props.onPress(6);
        }}
      >
        6 Month Average
      </Menu.Item>
      <Menu.Item
        onPress={() => {
          setShowMenu(false);
          props.onPress(9);
        }}
      >
        9 Month Average
      </Menu.Item>
      <Menu.Item
        onPress={() => {
          setShowMenu(false);
          props.onPress(12);
        }}
      >
        12 Month Average
      </Menu.Item>
    </Menu.Toggle>
  );
};

function TableControls({
  addButton = false,
  addButtonDisabled = false,
  refreshButtonDisabled = false,
  settingsButton = false,
  settingsButtonDisabled = false,
  leftControls = [],
  middleControls = [],
  rightControls = [],
  text,
  ...props
}) {
  let { onChange, onPress, onRefresh, onSettingsPress, value } = props;
  return (
    <SRow>
      <Col style={{ padding: '0' }} xs={1} md={1}>
        <SRow style={{ paddingLeft: '0' }}>
          {addButton && (
            <Col xs={4} style={{ paddingLeft: '0' }}>
              <AddButton
                onPress={() => onPress()}
                disabled={addButtonDisabled}
              />
            </Col>
          )}
          <Col xs={4} style={{ padding: '0' }}>
            <RefreshButton
              onPress={() => onRefresh()}
              disabled={refreshButtonDisabled}
            />
          </Col>
          {settingsButton && (
            <Col xs={4} style={{ padding: '0' }}>
              <SettingsButton
                onPress={onSettingsPress}
                disabled={settingsButtonDisabled}
              />
            </Col>
          )}
        </SRow>
      </Col>
      <Col style={{ padding: '0' }} xs={8} md={8}>
        <Center>
          <Text
            style={{
              fontSize: 18,
              color: 'black',
              fontFamily: 'Barlow_600SemiBold',
            }}
          >
            {text}
          </Text>
        </Center>
      </Col>
      <Col style={{ padding: '0' }} xs={3} md={3}>
        <SearchBar
          value={value}
          onChangeText={(item) => onChange({ item })}
          onClear={() => props.onClear()}
        />
      </Col>
    </SRow>
  );
}

export default TableControls;
