import { SearchBar as RNSearchBar } from '@rneui/themed';
import EntypoIcon from 'react-native-vector-icons/Entypo';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import styled from 'styled-components/native';
import Icon from './Icon';

const SSearchBar = styled(RNSearchBar)`
  width: 100%;
`;

function SearchBar(props) {
  return (
    <SSearchBar
      containerStyle={{
        backgroundColor: 'white',
        borderTopWidth: 0,
        borderBottomWidth: 0,
        width: '100%',
      }}
      inputContainerStyle={{
        backgroundColor: '#F7F7F7',
        width: '100%',
      }}
      inputStyle={{ borderBottomWidth: 1 }}
      searchIcon={<Icon Component={EntypoIcon} name='magnifying-glass' />}
      clearIcon={
        <Icon
          Component={MaterialIcons}
          name='clear'
          onPress={() => props?.onClear()}
        />
      }
      {...props}
    />
  );
}

export default SearchBar;
