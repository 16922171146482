import { axiosClient } from '.';
import { ERP_TOKEN_NAME } from '../utilities/constants';

function getAuthUserAPICall() {
  return axiosClient.get('/user/get');
}

async function loginAPICall(email, password) {
  return axiosClient.post('/login', { email, password });
}

async function nestedLoginAPICall() {
  return axiosClient.post('/nestedLogin');
}

async function loginAcumaticaAPICall(payload) {
  return axiosClient.post('/acmLogin', payload);
}

async function logoutAPICall() {
  return axiosClient.post('/logout');
}

const refreshTokenApiCall = (key) => {
  let url = `${process.env.REACT_APP_BASE_URL}/auth/token/refresh`;
  if (key === ERP_TOKEN_NAME) url += '/erp';
  return axiosClient({
    method: 'post',
    url,
  });
};

export {
  getAuthUserAPICall,
  loginAPICall,
  loginAcumaticaAPICall,
  logoutAPICall,
  refreshTokenApiCall,
  nestedLoginAPICall,
};
