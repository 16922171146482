import { action } from 'easy-peasy';

const actions = {
  setRolesAction: action((state, roles) => {
    state.roles.list = roles;
  }),
  setRolesSearch: action((state, rolesSearch) => {
    state.roles.search = rolesSearch;
  }),
};

export default actions;
