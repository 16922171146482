import styled from 'rn-css';
import { useStoreActions } from 'easy-peasy';
import { View } from 'react-native';

import DrawerTab from '../../presentational/Drawer/DrawerTab';
import Gap from '../../presentational/Gap';
import PrimaryButton from '../../presentational/Button/Button';

const Container = styled.View`
  padding: 12px;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

Container.Bottom = styled.View`
  margin-top: auto;
`;

function DrawerContent({ state, navigation, ...props }) {
  const { logoutThunk } = useStoreActions((actions) => actions);
  return (
    <Container {...props}>
      <View>
        {state.routes.map((route, index) => (
          <DrawerTab
            key={route.key}
            onPress={() => navigation.navigate(route.name)}
          >
            {route.name}
          </DrawerTab>
        ))}
      </View>

      <Container.Bottom>
        <Gap />
        <PrimaryButton fullWidth onPress={() => logoutThunk(navigation)}>
          Logout
        </PrimaryButton>
      </Container.Bottom>
    </Container>
  );
}

export default DrawerContent;
