import * as SecureStore from 'expo-secure-store';
import moment from 'moment';

const state = {
  maintenance: {
    search: '',
    edit: false,
    maintenanceModal: false,
    maintenanceActionsModal: false,
    maintenancePlans: null,
    maintenancePlanIndex: 0,
    maintenanceActions: null,
    selectedMaintenancePlan: {
      maintenancePlanId: 0,
      tenantId: '694B907A-9E90-4D69-86DA-64F0C40AF1BF',
      maintenancePlan: '',
      equipmentId: 1,
      serviceId: '',
      serviceName: '',
      projectCd: null,
      ataChapter: null,
      ataSubChapter: null,
      usageType: '',
      usageInterval: '',
      enteredBy: '1D655266-28CF-41CD-8BCD-E3FE0562AD53',
      active: true,
    },

    maintenanceAction: {
      selectedMaintenanceAction: {
        maintenanceActionId: null,
        tenantId: '694B907A-9E90-4D69-86DA-64F0C40AF1BF',
        equipmentId: '',
        maintenancePlanId: '3',
        maintenancePlan: '',
        usageType: '',
        completedAtHours: 46,
        completedAtCycles: 0,
        completedAtLandings: 0,
        completedAtDate: moment.utc().format('MM-DD-YYYY'),
        notes: '',
        enteredBy: '1D655266-28CF-41CD-8BCD-E3FE0562AD53',
        active: true,
        projectComplete: false,
      },
    },

    selectedMaintenancePlanItems: [],
    plansLoading: false,
    actionsLoading: false,
  },
  maintenanceMap: {},
  servicePackages: [],
  serviceProfiles: [],
  selectedServiceProfile: {},
  componentTreeId: '',
  componentList: [],
  componentPackageList: [],
};

export default state;
