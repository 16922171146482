import { useState, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import {
  Center,
  Menu,
  Pagination,
  Row,
  Table,
  Text,
  Input,
} from '@cfbs/cfbsstrap-native';
import { FontAwesome5 } from '@expo/vector-icons';
import SearchBar from '../presentational/SearchBar';
import TableControls from '../presentational/TableControls';

const styles = StyleSheet.create({
  header: { height: 50, backgroundColor: '#DCDCDC', width: '100%' },
  headerText: {
    height: 50,
    textAlign: 'left',
    justifyContent: 'left',
    alignItems: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: 10,
    fontSize: 18,
    color: 'black',
    fontFamily: 'Barlow_600SemiBold',
  },
  text: {
    display: 'flex',
    minHeight: 50,
    minWidth: 0,
    textAlign: 'left',
    justifyContent: 'left',
    alignItems: 'left',
    padding: 10,
    fontSize: 18,
    color: 'black',
    fontFamily: 'Barlow_400Regular',
  },
  dataWrapper: { marginTop: -1 },
  row: { minHeight: 50, backgroundColor: '#F7F7F7', width: '100%' },
});

function RmpTable({
  headers,
  data = [],
  widths = [],
  conditions = [],
  pageSize = 10,
  backgroundColor = null,
  addButton = true,
  addButtonText,
  addButtonDisabled,
  onRefresh,
  onPress,
  onDismiss,
  onChange,
  onClear,
  value,
  text,
  settingsButton,
  settingsButtonDisabled,
  onSettingsPress,
}) {
  const [currentPage, setCurrentPage] = useState(0);
  const [filteredList, setFilteredList] = useState(data.slice());
  const [paginatedRows, setPaginatedRows] = useState([]);
  const [showMenus, setShowMenus] = useState(headers.map((header) => false));

  const [filterValue, setFilterValue] = useState('');
  const [propertyNameValue, setPropertyNameValue] = useState('');
  const [sortDirectionValue, setSortDirectionValue] = useState('');
  const [sortTypeValue, setSortTypeValue] = useState('');

  const [columnToFilterIndex, setColumnToFilterIndex] = useState(-1);

  const lastPageNumDisplay = Math.ceil(filteredList.length / pageSize || 1);
  const lastPageIndex = lastPageNumDisplay - 1;

  useEffect(() => {
    let list = data.slice();

    if (propertyNameValue && sortDirectionValue && sortTypeValue) {
      list = list?.sort((jsonA, jsonB) =>
        sortList(
          jsonA,
          jsonB,
          propertyNameValue,
          sortDirectionValue,
          sortTypeValue
        )
      );
    }

    if (propertyNameValue && filterValue) {
      list = list?.filter((name) =>
        name[propertyNameValue]
          ?.toString()
          .toLowerCase()
          .includes(filterValue.toLowerCase())
      );
    }

    setFilteredList(list);
  }, [data]);

  function showMenu(columnIndex, disabled) {
    if (!disabled) {
      setShowMenus(
        showMenus.map((value, index) => (index === columnIndex ? true : value))
      );
    }
  }

  function hideMenu(columnIndex) {
    setShowMenus(
      showMenus.map((value, index) => (index === columnIndex ? false : value))
    );
  }

  useEffect(() => {
    let index = currentPage * pageSize;
    setPaginatedRows(filteredList.slice(index, index + pageSize));
  }, [filteredList, currentPage]);

  function isFilteringColumn(columnIndex) {
    return columnIndex === columnToFilterIndex;
  }

  function sortList(jsonA, jsonB, propertyName, direction, type = 'string') {
    let aValue;
    let bValue;
    if (type === 'string') {
      aValue = jsonA[propertyName]?.toString()?.toLowerCase();
      bValue = jsonB[propertyName]?.toString()?.toLowerCase();
    }

    if (type === 'number') {
      aValue = parseInt(jsonA[propertyName]);
      bValue = parseInt(jsonB[propertyName]);
    }

    if (type === 'date') {
      let futureDate = new Date(
        new Date().setFullYear(new Date().getFullYear() + 50)
      );
      let pastDate = new Date(
        new Date().setFullYear(new Date().getFullYear() - 50)
      );

      let naDate = direction === 'ASC' ? futureDate : pastDate;
      aValue =
        jsonA[propertyName] === 'N/A' ? naDate : new Date(jsonA[propertyName]);
      bValue =
        jsonB[propertyName] === 'N/A' ? naDate : new Date(jsonB[propertyName]);

      if (direction === 'ASC') {
        return aValue - bValue;
      }

      if (direction === 'DEC') {
        return bValue - aValue;
      }
    }

    if (direction === 'ASC') {
      if (aValue < bValue) {
        return -1;
      } else if (aValue > bValue) {
        return 1;
      }
    }

    if (direction === 'DEC') {
      if (bValue < aValue) {
        return -1;
      } else if (bValue > aValue) {
        return 1;
      }
    }

    return 0;
  }

  function filterList(text, propertyName, columnIndex) {
    let list = data.slice();

    if (propertyNameValue && sortDirectionValue) {
      list = list?.sort((jsonA, jsonB) =>
        sortList(jsonA, jsonB, propertyName, sortDirectionValue)
      );
    }

    list = list?.filter((name) =>
      name[propertyName]?.toString().toLowerCase().includes(text.toLowerCase())
    );
    setPropertyNameValue(propertyName);
    setFilterValue(text);
    setColumnToFilterIndex(columnIndex);
    setFilteredList(list);
    setCurrentPage(0);
  }

  function sortDataList(propertyName, columnIndex, direction, type) {
    let list = data
      .slice()
      ?.sort((jsonA, jsonB) =>
        sortList(jsonA, jsonB, propertyName, direction, type)
      );

    if (propertyNameValue && filterValue) {
      list = list.filter((name) =>
        name[propertyNameValue]
          ?.toString()
          .toLowerCase()
          .includes(filterValue.toLowerCase())
      );
    }

    setPropertyNameValue(propertyName);
    setColumnToFilterIndex(columnIndex);
    setFilteredList(list);
    setCurrentPage(0);
  }

  return (
    <>
      <TableControls
        text={text}
        addButton={addButton}
        addButtonText={addButtonText}
        addButtonDisabled={addButtonDisabled}
        settingsButton={settingsButton}
        settingsButtonDisabled={settingsButtonDisabled}
        onSettingsPress={onSettingsPress}
        value={value}
        onRefresh={onRefresh}
        onPress={() => onPress()}
        onDismiss={() => onDismiss()}
        onChange={(search) => {
          setCurrentPage(0);
          onChange(search);
        }}
        onClear={() => {
          setCurrentPage(0);
          onClear();
        }}
      />
      <Table striped border hover>
        <Table.tr style={styles.header}>
          {headers.map(
            (
              { columnName, propertyName, type, disabled = false },
              columnIndex
            ) => (
              <Table.th
                style={{ ...styles.headerText, flexGrow: widths[columnIndex] }}
                key={columnIndex}
              >
                <Menu.Toggle
                  showMenu={showMenus[columnIndex]}
                  onShowMenu={() => showMenu(columnIndex, disabled)}
                  onHideMenu={() => hideMenu(columnIndex)}
                  offsetMenuPosHorizontal={
                    columnIndex === headers.length - 1 ? -150 : 0
                  }
                  Toggle={
                    <Row>
                      <Text
                        style={{
                          fontSize: 18,
                          color: 'black',
                          fontFamily: 'Barlow_400Regular',
                        }}
                      >
                        {columnName}
                      </Text>
                      {isFilteringColumn(columnIndex) && (
                        <FontAwesome5 name='filter' size={20} />
                      )}
                    </Row>
                  }
                >
                  <Menu.Item>
                    <SearchBar
                      containerStyle={{
                        padding: 0,
                        backgroundColor: 'white',
                        borderTopWidth: 0,
                        borderBottomWidth: 0,
                        width: '100%',
                      }}
                      value={
                        columnToFilterIndex === columnIndex ? filterValue : ''
                      }
                      onChangeText={(text) =>
                        filterList(text, propertyName, columnIndex)
                      }
                      onClear={() => filterList('', propertyName, columnIndex)}
                    />
                  </Menu.Item>
                  <Menu.Item
                    onPress={() => {
                      setSortTypeValue(type);
                      setPropertyNameValue(propertyName);
                      setSortDirectionValue('ASC');
                      sortDataList(propertyName, columnIndex, 'ASC', type);
                    }}
                  >
                    Sort Ascending
                  </Menu.Item>
                  <Menu.Item
                    onPress={() => {
                      setSortTypeValue(type);
                      setPropertyNameValue(propertyName);
                      setSortDirectionValue('DEC');
                      sortDataList(propertyName, columnIndex, 'DEC', type);
                    }}
                  >
                    Sort Descending
                  </Menu.Item>
                  <Menu.Item
                    onPress={() => {
                      setFilteredList(data.slice());
                      setColumnToFilterIndex(-1);
                      setFilterValue('');
                      setSortDirectionValue('');
                    }}
                  >
                    Clear Filter
                  </Menu.Item>
                </Menu.Toggle>
              </Table.th>
            )
          )}
        </Table.tr>

        {paginatedRows.map((rowData, rowIndex) => (
          <Table.tr style={styles.row} key={rowIndex}>
            {Object.values(rowData).map((item, index) => (
              <Table.td
                style={{
                  ...styles.text,
                  textAlign:
                    headers[index]?.type === 'string'
                      ? 'left'
                      : headers[index]?.type === 'other'
                      ? 'center'
                      : 'right',
                  justifyContent:
                    headers[index]?.type === 'string'
                      ? 'left'
                      : headers[index]?.type === 'other'
                      ? 'center'
                      : 'right',
                  alignItems:
                    headers[index]?.type === 'string'
                      ? 'left'
                      : headers[index]?.type === 'other'
                      ? 'center'
                      : 'right',
                  flexGrow: widths[index],
                  backgroundColor: backgroundColor
                    ? backgroundColor(rowData, rowIndex)
                    : 'white',
                }}
                key={index}
              >
                {conditions &&
                conditions.find((condition) => condition.index === index)
                  ? conditions
                      .find((condition) => condition.index === index)
                      .func(item, rowIndex, rowData)
                  : item}
              </Table.td>
            ))}
          </Table.tr>
        ))}
      </Table>

      <View
        style={{
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'row',
        }}
      >
        <Center>
          <Pagination
            style={{
              marginTop: 20,
              alignContent: 'center',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Pagination.Item onPress={() => setCurrentPage(0)}>
              <Pagination.First />
            </Pagination.Item>

            <Pagination.Item
              onPress={() => setCurrentPage(Math.max(0, currentPage - 1))}
            >
              <Pagination.Previous />
            </Pagination.Item>

            <Pagination.Item>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <Text style={{ marginRight: 10 }}>Page</Text>
                <Input
                  style={{
                    borderWidth: 1,
                    borderColor: '#000',
                    padding: 5,
                    width: 50,
                    textAlign: 'center',
                  }}
                  keyboardType='numeric'
                  placeholder={currentPage + 1 + ''}
                  maxLength={Math.floor(Math.log10(lastPageNumDisplay) + 1)}
                  onChangeText={(text) => {
                    // Convert input text to an integer
                    const pageNumber = parseInt(text);

                    // Check if the pageNumber is a number, within the allowed range, and does not exceed the lastPageNumDisplay
                    if (
                      !isNaN(pageNumber) && // Check if it's a valid number
                      pageNumber >= 1 && // Check if it's not below the minimum
                      pageNumber <= lastPageNumDisplay // Check if it's not above the maximum
                    ) {
                      setCurrentPage(pageNumber - 1); // Update the currentPage, -1 because pages are 0-indexed
                    } else if (pageNumber > lastPageNumDisplay) {
                      // If the user tries to enter a page number greater than the last page, reset to the last page
                      setCurrentPage(lastPageNumDisplay - 1);
                    }
                  }}
                />
                <Text style={{ marginLeft: 10 }}>of {lastPageNumDisplay}</Text>
              </View>
            </Pagination.Item>

            <Pagination.Item
              onPress={() =>
                setCurrentPage(
                  Math.min(currentPage + 1, Math.floor(data.length / pageSize))
                )
              }
            >
              <Pagination.Next />
            </Pagination.Item>

            <Pagination.Item onPress={() => setCurrentPage(lastPageIndex)}>
              <Pagination.Last />
            </Pagination.Item>
          </Pagination>
        </Center>
      </View>
    </>
  );
}

export default RmpTable;
